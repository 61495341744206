import { Map } from 'immutable';
import { isEmpty, orderBy, sortBy } from 'lodash';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

import * as sessionSelectors from 'selectors/session';

import {
  CUSTOM_PAYROLL,
  CUSTOM_PAYROLL_PERIOD,
  LAST_7_DAYS_PAYROLL,
  LAST_7_DAYS_PAYROLL_PERIOD,
  VALUE_TYPES,
} from 'features/timesheets/constants';
import { duration, parseDate } from 'features/timesheets/util';

import { df, isFutureDate, moment } from 'util/dateTime';
import { toI18n } from 'util/i18n';

const sortByTimestamp = row => row.get('timestamp');
const sortByName = row => row.getIn(['employee', 'full_name']).toLowerCase();

const buildPageSelector = key => state =>
  state.getIn(['timesheets', 'page', key]);

export const getRows = buildPageSelector('rows');
export const getShowNav = buildPageSelector('showNav');
export const getJobsSummary = buildPageSelector('jobsSummary');
export const getDaysSummary = buildPageSelector('daysSummary');
export const getRolesSummary = buildPageSelector('rolesSummary');
export const getCacheKey = buildPageSelector('cacheKey');
export const getLoadingRows = buildPageSelector('loadingRows');
export const getLoadingTotals = buildPageSelector('loadingTotals');
export const getLoadingInitData = buildPageSelector('loadingInitData');
export const getAllColumns = buildPageSelector('columns');
export const getLoadingSummaries = buildPageSelector('loadingSummaries');
export const getLocationRolesUpdated = buildPageSelector(
  'locationRolesUpdated'
);
export const getLocationDataFetched = buildPageSelector('locationDataFetched');

export const getBreakPenaltiesEnabled = buildPageSelector(
  'breakPenaltiesEnabled'
);

export const isLocationEligibleForTipPooling = buildPageSelector(
  'locationEligibleForTipPooling'
);
export const getIsTipPoolingV375Enabled = createSelector(
  sessionSelectors.getActiveTipPolicyId,
  activeTipPolicyId => !!activeTipPolicyId
);
export const shouldRefreshTipOuts = buildPageSelector('refreshTipOuts');
export const getColumnsForView = createCachedSelector(
  getAllColumns,
  (_, props) => props.groupBy,
  (columns, groupBy) => {
    if (groupBy === 'role') {
      columns = columns.filter(c => c.get('key') !== 'role');
      columns = columns.insert(
        1,
        Map({ key: 'date', visible: true, hidden: true })
      );
    }

    return columns;
  }
)((_, props) => `getColumnsForView-${props.groupBy}`);

export const getPayrollProvider = createSelector(
  buildPageSelector('payrollProvider'),
  payrollProvider =>
    (payrollProvider || Map()).merge({
      provider_key: payrollProvider
        ? payrollProvider.get('provider_key')
        : 'csv', // default value
    })
);

export const getCollapsedCards = buildPageSelector('collapsedCards');

export const getCardIsCollapsed = createCachedSelector(
  getCollapsedCards,
  (_, props) => props.group.key,
  (cards, key) => cards.has('all') || cards.has(key)
)((state, props) => `getCardIsCollapsed-${props.group.key}`);

export const getEmployeeSortBy = buildPageSelector('employeeSortBy');

const getColumnLabel = (column, groupBy) => {
  let key;

  if (column.get('key') === 'main') {
    key = groupBy === 'employee' ? 'date' : 'employee';
  } else {
    key = column.get('key');
  }

  return toI18n(`timesheets.react_page.columns.${key}`);
};

export const getColumns = createCachedSelector(
  getColumnsForView,
  (_, props) => props.groupBy,
  (columns, groupBy) =>
    columns
      .filter(column => column.get('visible'))
      .map(column =>
        column
          .set('name', getColumnLabel(column, groupBy))
          .set('value-type', VALUE_TYPES[column.get('key')] || 'number')
      )
)((state, props) => `getColumns-${props.groupBy}`);

export const getColumnOptions = createCachedSelector(
  getColumnsForView,
  (_, props) => props.groupBy,
  (columns, groupBy) =>
    columns
      .filter(column => !column.get('hidden'))
      .map(column => ({
        value: column.get('visible'),
        key: column.get('key'),
        label: getColumnLabel(column, groupBy),
        disabled: ['role', 'wage_rate', 'timecard'].includes(column.get('key')),
      }))
      .toArray()
)((state, props) => `getColumnOptions-${props.groupBy}`);

const DEFAULT_COLUMN_EXCLUSIONS = { main: true };

export const getColumnExclusions = () => DEFAULT_COLUMN_EXCLUSIONS;

export const getNonExcludedColumns = createSelector(
  getColumns,
  getColumnExclusions,
  (columns, exclusions) =>
    columns.filter(column => !exclusions[column.get('key')])
);

export const getTimecardGroupApprovalStatus = (_, props) => {
  const timecards = props.group.rows.filter(row => row.get('actual_start_at'));

  if (timecards.length > 0) {
    const salariedCount = timecards.filter(row =>
      row.get('wage_rate')?.includes('/yr')
    ).length;

    if (salariedCount === timecards.length) return null;

    const approvedCount = timecards.filter(row => row.get('approved')).length;

    return approvedCount === timecards.length ? 'locked' : 'unlocked';
  }

  return null;
};
export const getTimecardApprovalStatus = (_, props) => {
  const timecard = props.timecard;

  if (timecard.get('actual_start_at')) {
    return timecard.get('approved') ? 'locked' : 'unlocked';
  }

  return null;
};

export const getTimecardValidForApproval = (_, props) => {
  const { timecard } = props;

  if (
    !timecard.get('actual_start_at') ||
    isFutureDate(timecard.get('actual_start_at'))
  )
    return false;

  return true;
};

export const getTimecardLockVisibility = createSelector(
  getTimecardValidForApproval,
  sessionSelectors.getLockTimesheetRolePermission,
  sessionSelectors.getTimesheetApprovalEnabled,
  getTimecardApprovalStatus,
  (
    timecardValidForApproval,
    roleAccess,
    settingEnabled,
    timecardApprovalStatus
  ) => {
    if (!timecardValidForApproval || !settingEnabled) return 'hidden';

    if (!roleAccess) {
      return timecardApprovalStatus === 'locked' ? 'disabled_locked' : 'hidden';
    }

    return 'enabled';
  }
);

export const getLocationRoles = buildPageSelector('locationRoles');
export const getLocationEmployees = buildPageSelector('locationEmployees');
export const getLoadingLocationData = buildPageSelector('loadingLocationData');
export const getLocationMandatedBreaks = buildPageSelector('mandatedBreaks');
export const getHideWithoutHours = buildPageSelector('hideWithoutHours');
export const getLocationMandatedBreaksMap = createSelector(
  getLocationMandatedBreaks,
  breaks => breaks.reduce((map, mb) => map.set(mb.get('id'), mb), Map())
);

export const getSortColumn = buildPageSelector('sortColumn');
export const canImportCreditTips = buildPageSelector('canImportCreditTips');
export const getDefaultColumns = buildPageSelector('defaultColumns');
export const canImportTimecards = buildPageSelector('canImportTimecards');

export const getSubmissionDeadlineAdjustedBanner = buildPageSelector(
  'submissionDeadlineAdjustedBanner'
);
export const getCompanyHasTimecards = buildPageSelector('companyHasTimecards');

export const showMakeBreaksOptional = buildPageSelector(
  'showMakeBreaksOptional'
);

export const showTipPoolingTooltip = buildPageSelector(
  'showTimesheetsTipPoolingTooltip'
);
export const importCreditTips = buildPageSelector('importCreditTips');

export const getAlwaysVisibleColumns = buildPageSelector(
  'alwaysVisibleColumns'
);

export const getPayrollPeriod = buildPageSelector('payrollPeriod');
export const getStartDate = buildPageSelector('startDate');
export const getEndDate = buildPageSelector('endDate');

const buildGeneralSelector = key => state =>
  state.getIn(['timesheets', 'general', key]);

export const getShowTimesheetsFTU = buildGeneralSelector(
  'timesheetsFTUEnabled'
);

const buildTimesheetZeroStateSelector = key => state =>
  state.getIn(['timesheets', 'general', 'timesheetsZeroState', key]);

export const getShouldSeeEmbeddedCTA = buildTimesheetZeroStateSelector(
  'shouldSeeEmbeddedCTA'
);

export const getShouldSeeTimesheetsEmptyState = buildTimesheetZeroStateSelector(
  'shouldSeeTimesheetsEmptyState'
);

export const getShouldSeeTimesheetsZeroState = buildTimesheetZeroStateSelector(
  'shouldSeeTimesheetsZeroState'
);

export const getCanShowGroupByTooltip = buildGeneralSelector(
  'canShowGroupByTooltip'
);

const getMonetizationUpsellExportModalOpen = buildGeneralSelector(
  'monetizationUpsellExportModalOpen'
);

export const getIsMonetizationUpsellExportModalOpen = createSelector(
  getMonetizationUpsellExportModalOpen,
  sessionSelectors.getShowTimesheetExportUpsellInterrupt,
  (
    monetizationUpsellExportModalOpen,
    showTimesheetExportMonetizationUpsellModal
  ) =>
    monetizationUpsellExportModalOpen &&
    showTimesheetExportMonetizationUpsellModal
);

export const getDefaultColumnOptions = createCachedSelector(
  getDefaultColumns,
  getAlwaysVisibleColumns,
  (_, props) => props.groupBy,
  (columns, alwaysVisibleColumns, groupBy) =>
    columns
      .filter(c => !alwaysVisibleColumns.includes(c.get('key')))
      .map(c => ({
        value: c.get('visible'),
        key: c.get('key'),
        label: getColumnLabel(c, groupBy),
        disabled: ['role', 'wage_rate', 'timecard'].includes(c.get('key')),
      }))
      .toArray()
)((state, props) => `getDefaultColumnOptions-${props.groupBy}`);

export const getPayrollPeriods = createSelector(
  buildPageSelector('payrollPeriods'),
  payrollPeriods =>
    payrollPeriods
      ? payrollPeriods.map(payrollPeriod =>
          payrollPeriod.merge({
            start_date: moment(payrollPeriod.get('start_date'), 'MM/DD/YYYY'),
            end_date: moment(payrollPeriod.get('end_date'), 'MM/DD/YYYY'),
          })
        )
      : []
);

const parseRoleColumn = (row, roles) => {
  const role = roles[row.get('role_id')];

  if (role) {
    return role.get('name');
  }

  return '';
};

const getSortColumnData = (columnKey, row) => {
  switch (columnKey) {
    case 'main':
      return row.get('mainSortData');
    case 'date':
      return row.get('timestamp');
    case 'timecard':
      return row.get('actual_start_at_unix');
    case 'cash_tips':
    case 'credit_tips':
    case 'wage_rate':
    case 'costs': {
      const value = row.get(columnKey);
      return value ? parseFloat(value.substr(1)) : value;
    }
    case 'role':
      return row.get(columnKey);
    default: {
      const value = row.get(columnKey);
      return value ? parseFloat(value) : value;
    }
  }
};

const getIssueIcon = row => {
  const firstIssue = row.getIn(['issues_collection', 0]);

  if (firstIssue) {
    return firstIssue.get('type');
  }
};

const parseIssuesColumn = row => {
  const firstIssue = row.getIn(['issues_collection', 0]);

  if (firstIssue) {
    let str = toI18n(
      `timecard_modal_v3.issues.${firstIssue.get('type')}.content`
    );
    const otherIssues = row
      .get('issues_collection')
      .slice(1, row.get('issues_collection').size);
    if (otherIssues.size) {
      str += `, + ${otherIssues.size} more`;
    }

    return str;
  }
};

const groupsByEmployeeName = (groups, employees, employeeSortBy) =>
  sortBy(groups, group => {
    const fname = employees[group.key]?.get('first_name');
    const lname = employees[group.key]?.get('last_name') || ' ';
    let sortedName;
    if (employeeSortBy === 'first_name') {
      sortedName = fname + lname;
    } else {
      sortedName = lname + fname;
    }
    return sortedName.toLowerCase();
  });

const getMainData = (groupBy, row, employees) =>
  groupBy === 'employee'
    ? moment(row.get('date'), 'MM/DD/YYYY').format('ddd MMM D')
    : employees[row.get('job_id')].get('full_name');

const getMainSortData = (groupBy, row, employees, employeeSortBy) =>
  groupBy === 'employee'
    ? row.get('timestamp')
    : employees[row.get('job_id')].get(employeeSortBy) || '';

const formatRows = (jobRows, roles, groupBy, employees, employeeSortBy) =>
  jobRows
    .map(row => {
      const extraColumns = {
        main: getMainData(groupBy, row, employees),
        mainSortData: getMainSortData(groupBy, row, employees, employeeSortBy),
        issues: getIssueIcon(row),
      };

      if (groupBy === 'role') {
        extraColumns.date = moment(row.get('date'), 'MM/DD/YYYY').format(
          'ddd MMM D'
        );
      } else {
        extraColumns.role = parseRoleColumn(row, roles);
      }

      return row.merge(extraColumns);
    })
    .toArray();

const getSortColumnKey = createSelector(getSortColumn, sortColumn =>
  sortColumn.get('columnKey')
);
const getSortColumnDirection = createSelector(getSortColumn, sortColumn =>
  sortColumn.get('direction')
);

export const getRoles = createSelector(getLocationRoles, roles =>
  roles.reduce((data, role) => {
    data[role.get('id')] = role;
    return data;
  }, {})
);

export const getEmployees = createSelector(getLocationEmployees, employees =>
  employees.reduce((data, e) => {
    data[e.get('id')] = e;
    return data;
  }, {})
);

export const getEmployeeFilterOptions = createSelector(
  getLocationEmployees,
  employees => {
    const options = employees
      .sortBy(employee => employee.get('full_name').toLowerCase())
      .map(employee => ({
        value: employee.get('id').toString(),
        label: employee.get('full_name'),
      }))
      .toArray();
    return options;
  }
);

export const getFilteredTableData = createCachedSelector(
  getRows,
  (_, props) => props.filter,
  (rows, filter) => {
    rows = rows.filter(row => row.get('key'));

    if (filter && filter !== 'all') {
      filter = parseInt(filter, 10);
      rows = rows.filter(row => filter === row.get('job_id'));
    }

    return rows;
  }
)((state, props) => `getFilteredTableData-${props.filter}`);

const getGroupedRows = createCachedSelector(
  getFilteredTableData,
  getEmployees,
  getRoles,
  (_, props) => props.groupBy,
  (_, props) => props.filter,
  getEmployeeSortBy,
  getLoadingLocationData,
  getHideWithoutHours,
  getStartDate,
  getEndDate,
  (
    rows,
    employees,
    roles,
    groupBy,
    filter,
    employeeSortBy,
    isLoading,
    hideWithoutHours,
    startDate,
    endDate
  ) => {
    let groups = [];
    const tmpEmployees = Object.assign({}, employees);

    if (isLoading) {
      return [];
    }

    if (groupBy === 'employee') {
      rows
        .sortBy(row =>
          (
            employees[row.get('job_id')]?.get(employeeSortBy) || ''
          ).toLowerCase()
        )
        .groupBy(row => row.get('job_id'))
        .forEach((jobRows, jobId) => {
          groups.push({
            key: jobId,
            job: employees[jobId],
            rows: formatRows(jobRows, roles, groupBy, employees),
          });
          delete tmpEmployees[jobId];
        });

      groups = groupsByEmployeeName(groups, employees, employeeSortBy);

      if (!hideWithoutHours) {
        if (filter === 'all') {
          Object.keys(tmpEmployees).forEach(id => {
            groups.push({
              key: parseInt(id, 10),
              job: employees[id],
              rows: [],
            });
          });

          return groupsByEmployeeName(groups, employees, employeeSortBy);
        }

        // Re-apply filter
        if (filter !== 'all' && tmpEmployees[filter]) {
          return [
            { key: parseInt(filter, 10), job: employees[filter], rows: [] },
          ];
        }
      }
    } else if (groupBy === 'day') {
      const rowGroups = rows
        .sortBy(row => row.get('timestamp'))
        .groupBy(row => row.get('date'));

      const minDate = window.moment.min([
        window.moment(startDate),
        window.moment([...rowGroups.keys()][0]),
      ]);

      const maxDate = window.moment.max([
        window.moment(endDate),
        window.moment([...rowGroups.keys()].at(-1)),
      ]);

      window.moment.range(minDate, maxDate).by('day', day => {
        const key = day.format('MM/DD/YYYY');
        const jobRows = rowGroups.get(key);

        if (jobRows) {
          groups.push({
            key,
            day,
            rows: formatRows(
              jobRows,
              roles,
              groupBy,
              employees,
              employeeSortBy
            ),
          });
        } else if (!hideWithoutHours) {
          groups.push({ key, day, rows: [] });
        }
      });
    } else if (groupBy === 'role') {
      const rowGroups = rows
        .sortBy(row => row.get('timestamp'))
        .groupBy(row => row.get('role_id') || null);

      rowGroups.forEach((groupRows, roleId) => {
        const key = `role-${roleId || 'none'}`;
        const role = roles[roleId];
        groups.push({
          key,
          role: role || Map({ name: 'No Role', id: 0 }),
          rows: formatRows(
            groupRows,
            roles,
            groupBy,
            employees,
            employeeSortBy
          ),
        });
      });

      groups = sortBy(groups, group => {
        if (group.role.get('name') === 'No Role') {
          return '';
        }

        return group.role.get('name').toLowerCase();
      });
    }

    return groups;
  }
)((state, props) => `getGroupedRows-${props.groupBy}-${props.filter}`);

export const getTableData = createSelector(
  getGroupedRows,
  getSortColumnKey,
  getSortColumnDirection,
  (tableData, sortColumnKey, sortColumnDirection) =>
    tableData.map(group => {
      if (group.rows.length < 2) {
        return group;
      }

      group.rows = orderBy(
        group.rows,
        row => getSortColumnData(sortColumnKey, row),
        sortColumnDirection
      );
      return { ...group };
    })
);

export const getGroupKeys = createSelector(getGroupedRows, tableData =>
  tableData.map(group => group.key)
);

export const getRowIndices = createSelector(
  getTableData,
  getFilteredTableData,
  (tableData, rows) => {
    let i = 0;
    // Sort by key here is important since the order of rows
    // on the client should match the order of rows
    // on the server to correctly print the timesheets
    const rowIndices = rows
      .toList()
      .sortBy(a => a.get('key').toString())
      .reduce((memo, row) => {
        memo[row.get('key')] = i;
        i += 1;
        return memo;
      }, {});

    const keys = [];

    tableData.forEach(group => {
      group.rows.forEach(r => keys.push(rowIndices[r.get('key')]));
    });

    return keys;
  }
);

export const getSummaryRow = createCachedSelector(
  getJobsSummary,
  getDaysSummary,
  getRolesSummary,
  (_, props) => props.groupBy,
  (_, props) => props.group,
  (_, props) => props.filter,
  (jobsSummary, daysSummary, rolesSummary, groupBy, group, filter) => {
    let row;
    if (groupBy === 'employee') {
      row = jobsSummary.get(group.job.get('id').toString());
    } else if (
      groupBy === 'day' &&
      daysSummary.has(group.day.format('YYYY-MM-DD'))
    ) {
      row =
        filter === 'all'
          ? daysSummary.getIn([group.day.format('YYYY-MM-DD'), 'all'])
          : daysSummary.getIn([
              group.day.format('YYYY-MM-DD'),
              'filtered',
              filter,
            ]);
    } else if (
      groupBy === 'role' &&
      rolesSummary?.has(group.role.get('id').toString())
    ) {
      row =
        filter === 'all'
          ? rolesSummary.getIn([group.role.get('id').toString(), 'all'])
          : rolesSummary.getIn([
              group.role.get('id').toString(),
              'filtered',
              filter,
            ]);
    }
    if (!row) {
      row = Map();
    }

    const timecardCount = group.rows.filter(
      r => !!r.get('actual_start_at')
    ).length;
    return row.merge({
      issues: toI18n('timesheets.react_page.summary_row.issues', {
        props: {
          count: group.rows.reduce((sum, r) => {
            if (r.get('issues_collection')) {
              return sum + r.get('issues_collection').size;
            }
            return sum;
          }, 0),
        },
      }),
      timecard: toI18n('timesheets.react_page.summary_row.timecards', {
        props: { count: timecardCount },
      }),
      timecardCount,
    });
  }
)(
  (state, props) =>
    `summary-${
      (props.groupBy === 'employee' && props.group.job.get('id')) ||
      (props.groupBy === 'day' && props.group.day.format('YYYY-MM-DD')) ||
      (props.groupBy === 'role' && props.group.role.get('id'))
    }`
);

export const selectHasMissingTimebreak = createSelector(
  getFilteredTableData,
  rows => {
    if (!rows) {
      return false;
    }

    rows = rows
      .filter(row =>
        row.get('issues_collection')
          ? row.get('issues_collection').size > 0
          : false
      )
      .toArray();

    return rows.some(
      row =>
        !isEmpty(
          row
            .get('issues_collection')
            .filter(issue => issue.get('type') === 'missing_break')
            .toArray()
        )
    );
  }
);

export const getRowsWithIssues = createCachedSelector(
  getFilteredTableData,
  getEmployees,
  (_, props) => props.groupBy,
  (rows, employees, groupBy) => {
    rows = rows
      .filter(row =>
        row.get('issues_collection')
          ? row.get('issues_collection').size > 0
          : false
      )
      .toArray();

    rows = rows.map(row => {
      const day = moment(row.get('date'), 'MM/DD/YYYY');
      let timecardDuration = '--';

      if (row.get('actual_start_at') && row.get('actual_end_at')) {
        const actualStartAt = moment(
          row.get('actual_start_at'),
          'MM/DD/YYYY HH:mm'
        );
        const actualEndAt = moment(
          row.get('actual_end_at'),
          'MM/DD/YYYY HH:mm'
        );
        timecardDuration = duration(actualEndAt.diff(actualStartAt, 'minutes'));
      }

      return row.merge({
        employee: employees[row.get('job_id')],
        dayDuration: `${day.format('ddd MMM D')}, ${timecardDuration}`,
        issues: parseIssuesColumn(row),
      });
    });

    return sortBy(
      rows,
      groupBy === 'employee'
        ? [sortByTimestamp, sortByName]
        : [sortByName, sortByTimestamp]
    );
  }
)((state, props) => `getRowsWithIssues-${props.groupBy}`);

export const getFooterTotals = createSelector(
  buildPageSelector('footerTotals'),
  getFilteredTableData,
  getRolesSummary,
  getStartDate,
  getEndDate,
  (totals, rows, rolesSummary, startDate, endDate) => {
    const rolesSummaryKeys = rolesSummary
      ? rolesSummary.keySeq().toArray()
      : [];
    const rolesSummaryKeysCount = rolesSummaryKeys.filter(
      key => key !== ''
    ).length;

    const count = rows.reduce((sum, r) => {
      if (r.get('issues_collection')) {
        return sum + r.get('issues_collection').size;
      }
      return sum;
    }, 0);

    const timecardCount = rows.filter(r => !!r.get('actual_start_at')).size;

    totals = totals
      .set(
        'timecard',
        toI18n('timesheets.react_page.summary_row.timecards', {
          props: { count: timecardCount },
        })
      )
      .set(
        'role',
        toI18n('timesheets.react_page.summary_row.roles', {
          props: { count: rolesSummaryKeysCount },
        })
      )
      .set(
        'date',
        `${parseDate(startDate).format(df('short_only_date'))} - ${parseDate(
          endDate
        ).format(df('short_only_date'))}`
      )
      .set(
        'issues',
        toI18n('timesheets.react_page.summary_row.issues', { props: { count } })
      );

    return totals;
  }
);

const getPayrollPeriodWithDates = (startDateStr, endDateStr) => {
  const startDate = parseDate(startDateStr);
  const endDate = parseDate(endDateStr);
  return Map({
    start_date: startDate,
    end_date: endDate,
    start_date_label: startDate.format(df('pretty_no_wday_stripped')),
    end_date_label: endDate.format(df('pretty_no_wday_stripped')),
  });
};

export const getSelectedPayrollPeriod = createSelector(
  getPayrollPeriod,
  getStartDate,
  getEndDate,
  getPayrollPeriods,
  (payrollPeriod, startDate, endDate, payrollPeriods) => {
    if (payrollPeriod === LAST_7_DAYS_PAYROLL) {
      return getPayrollPeriodWithDates(startDate, endDate).merge(
        LAST_7_DAYS_PAYROLL_PERIOD
      );
    } else if (payrollPeriod === CUSTOM_PAYROLL) {
      return getPayrollPeriodWithDates(startDate, endDate).merge(
        CUSTOM_PAYROLL_PERIOD
      );
    }
    const selectedPayrollPeriod = payrollPeriods.find(
      e => e.get('label_type') === payrollPeriod
    );

    if (
      selectedPayrollPeriod?.get('start_date').diff(startDate, 'days') === 0 &&
      selectedPayrollPeriod.get('end_date').diff(endDate, 'days') === 0
    ) {
      return selectedPayrollPeriod;
    }

    return getPayrollPeriodWithDates(startDate, endDate).merge(
      CUSTOM_PAYROLL_PERIOD
    );
  }
);

export const getShouldUserSeeTimesheetsZeroState = createSelector(
  getShouldSeeTimesheetsZeroState,
  sessionSelectors.getTimesheetsGuidedSetupComplete,
  (shouldSeeZeroState, timesheetsGuidedSetupComplete) =>
    shouldSeeZeroState &&
    !timesheetsGuidedSetupComplete &&
    window.sessionStorage.getItem('timesheets_guided_setup_maybe_later') !==
      'true'
);

export const getTipEngineAlert = state =>
  state.getIn(['timesheets', 'page', 'tipEngineAlert']);

export const getLoadingTimesheetData = createSelector(
  getLoadingRows,
  getLoadingTotals,
  (loadingRows, loadingTotals) => loadingRows || loadingTotals
);

export const getShowIntroductoryManageTipsButton = state =>
  state.getIn(['timesheets', 'page', 'showIntroductoryManageTipsButton']);

export const getIsSubscribedToTipPooling = state =>
  state.getIn(['timesheets', 'page', 'subscribedToTipPooling']);

export const getShowTipPoolingSubscribedTooltip = state =>
  state.getIn(['timesheets', 'page', 'showTipPoolingSubscribedTooltip']);
