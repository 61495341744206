import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchJSON, postJSON, putJSON } from 'api/fetch';

import { DEFAULT_TIP_POLICY_PARAMS } from './constants';

export const fetchLocationTipPolicies = createAsyncThunk(
  'tipPoolingPolicies/fetchLocationTipPolicies',
  () => fetchJSON('/timesheets/tip_pooling_policies')
);

export const createTipPolicy = createAsyncThunk(
  'tipPoolingPolicies/createTipPolicy',
  (_, { rejectWithValue }) =>
    postJSON(
      `/timesheets/tip_pooling_policies/`,
      DEFAULT_TIP_POLICY_PARAMS
    ).catch(err => err.response.json().then(body => rejectWithValue(body)))
);

export const disableTipPolicy = createAsyncThunk(
  'tipPoolingPolicies/disableTipPolicy',
  (
    { id, archived_at, recalculation_start_date, recalculation_end_date },
    { rejectWithValue }
  ) =>
    putJSON(`/timesheets/tip_pooling_policies/${id}/`, {
      archived_at,
      recalculation_start_date,
      recalculation_end_date,
    }).catch(err => err.response.json().then(body => rejectWithValue(body)))
);

export const enableTipPolicy = createAsyncThunk(
  'tipPoolingPolicies/enableTipPolicy',
  ({ id }, { rejectWithValue }) =>
    putJSON(`/timesheets/tip_pooling_policies/${id}/`, {
      archived_at: null,
    }).catch(err => err.response.json().then(body => rejectWithValue(body)))
);

export const updateTipPolicy = createAsyncThunk(
  'tipPoolingPolicies/updateTipPolicy',
  ({ id, data }, { rejectWithValue }) =>
    putJSON(`/timesheets/tip_pooling_policies/${id}/`, data).catch(err =>
      err.response.json().then(body => rejectWithValue(body))
    )
);

export const addNewRole = createAsyncThunk(
  'tipPoolingPolicies/addNewRole',
  ({ roleName }, { rejectWithValue }) =>
    postJSON(`/roles`, { role: { name: roleName } }).catch(err =>
      err.response.json().then(body => rejectWithValue(body))
    )
);

export const requestCallbackToUnsubscribe = createAsyncThunk(
  'help/requestCallback',
  ({ phoneNumber }) =>
    postJSON('/request_callback', {
      callback: {
        selected_reason: 'unsubscribe from tip manager',
        phone_number: phoneNumber,
      },
    })
);
