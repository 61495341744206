import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICE_NAME } from './constants';
import { ApprovalState, BackgroundCheckState } from './types';

const initialState: BackgroundCheckState = {
  approvalStatus: ApprovalState.Unapplied,
};

const backgroundCheckSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setApprovalStatus(state, action: PayloadAction<ApprovalState>) {
      state.approvalStatus = action.payload;
    },
  },
});

export const { setApprovalStatus } = backgroundCheckSlice.actions;

export { backgroundCheckSlice };

export const { reducer } = backgroundCheckSlice;
