export enum ApprovalState {
  Unapplied = 'Unapplied',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Pending = 'Pending',
}

export interface BackgroundCheckState {
  approvalStatus: ApprovalState;
}
