import './AddTeamDrawerContainer.scss';

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import Box from 'fe-design-base/atoms/Box';
import Toast from 'fe-design-base/molecules/Toast';
import { Form, Formik } from 'formik';
import { fromJS } from 'immutable';
import { isEmpty, partial } from 'lodash';
import capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';

import {
  fetchCompanyRoles,
  showBulkAddModal,
  showConnectPosModal,
  showSyncEmployeesModal,
} from 'actions/addTeam';
import * as employeeActions from 'actions/employeeView';
import { updateActiveUserCountByOne } from 'actions/session';

import {
  getCurrentLocationIsIntegratedPayrollPartner,
  getCurrentLocationIsIntegratedPOSPartner,
  getCurrentLocationIsPayrollForSyncPage,
  getCurrentLocationIsPOSForSyncPage,
  getHasManagersFeatureAccess,
} from 'selectors/addTeam';
import {
  getCurrentLocationId,
  getCurrentLocationPartner,
  getCurrentLocationPayrollProvider,
  getCurrentLocationTierId,
  getCurrentLocationTierName,
  getIsPayrollEnrolled,
  getRolloutEnabled,
  selectActiveUsersCount,
} from 'selectors/session';

import BottomDrawer from 'features/bottomDrawers/BottomDrawer';
import {
  ACTIVE_PAYROLL,
  IMPLEMENTATION,
  PRE_FIRST_RUN,
} from 'features/payroll/constants';
import {
  fetchPayrollInfo as getFetchPayrollInfo,
  getPayrollState,
} from 'features/payroll/PayrollInfo/PayrollInfoSlice';
import {
  addTeamMember,
  createPayrollCheckEntity,
  rehireTeamMember,
  updateTeamMember,
} from 'features/team/actions';
import AddEmployeeForm from 'features/team/components/AddEmployeeForm';
import AddEmployeeFormStepOne from 'features/team/components/AddEmployeeForm/AddEmployeeFormWithSteps/StepOne';
import AddEmployeeFormStepTwo from 'features/team/components/AddEmployeeForm/AddEmployeeFormWithSteps/StepTwo';
import {
  ADD_EMPLOYEE_FORM_SCHEMA,
  initialValues,
  ONBOARDING_OPTIONS,
  SCROLL_BEHAVIOR,
} from 'features/team/components/AddEmployeeForm/constants';
import {
  createEmployeePayload,
  formHasContent,
  getMessageKey,
} from 'features/team/components/AddEmployeeForm/util';
import DrawerFooter from 'features/team/components/DrawerFooter';
import DrawerHeader from 'features/team/components/DrawerHeader';
import ExitModal from 'features/team/components/ExitModal';
import { RESULT } from 'features/team/constants';
import {
  getIsDefaultManager,
  selectCalledFrom,
  selectCurrentTeamMember,
  selectDrawerIsOpen,
  selectOnCloseDrawerCallback,
  selectToastIsOpen,
  selectToastProps,
} from 'features/team/selectors';
import {
  closeAddTeamDrawer,
  closeToast,
  openSuccessModal,
} from 'features/team/slice';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  FORM_TYPES,
  getUxTracking,
  payrollOnboardingOptions,
  PRODUCT_AREAS,
  SOURCE,
} from 'features/teamView/tracking';
import { TIER_NAMES } from 'features/tiers/constants';

import { cxHelpers } from 'util/className';
import { datadogLogError } from 'util/datadogLogs';
import { error as flashError } from 'util/flashNotice';
import { toI18n } from 'util/i18n';
import { browserHistory, importEmployeesRoute } from 'util/router';
import { trackUxEvent } from 'util/tracking';
import { withUxRoot } from 'util/uxEvents';

import { I18N_PATH } from '../../components/AddEmployeeSuccessModal/constants';
import EmptyRosterOptions from '../../components/EmptyRosterOptionGroup/EmptyRosterOptionGroup';

import ImportContainerCTA from './ImportContainerCTA';

const { cx } = cxHelpers('AddTeamDrawerContainer');
const SHOW_STEPPER_PAYROLL_STATUSES = [
  IMPLEMENTATION,
  PRE_FIRST_RUN,
  ACTIVE_PAYROLL,
];

const AddTeamDrawerContainer = ({
  animations,
  currentLocationTierId,
  currentTeamMember,
  hasEmployees,
  isAIOLocation,
  isIntegratedPOSPartner,
  isIntegratedPayrollPartner,
  isOpen,
  isPayrollEnrolled,
  isSyncPOSPartner,
  isSyncPayrollPartner,
  locationId,
  onAddTeamMember,
  onCloseDrawer,
  onCloseDrawerCallback,
  onCloseToast,
  onCreatePayrollCheckEntity,
  onFetchCompanyRoles,
  onIncrementUserCount,
  onOpenSuccessModal,
  onRehireTeamMember,
  onUpdateTeamMember,
  onShowConnectPosModal,
  onShowSyncEmployeesModal,
  onShowBulkAddModal,
  partner,
  payrollProvider,
  toastIsOpen,
  toastProps: { roleValue },
  isDefaultManager,
  calledFrom,
  updateUserInfo,
  payrollGrowthBenefitsAddTeamDrawerEnabled,
  payrollState,
  fetchPayrollInfo,
}) => {
  const [isExiting, setIsExiting] = useState(false);
  const [isAdding, setIsAdding] = useState(isEmpty(currentTeamMember));
  const [isEditing] = useState(!isEmpty(currentTeamMember));
  const [hasEmailError, setHasEmailError] = useState(false);
  const [hasPhoneError, setHasPhoneError] = useState(false);
  const [checkEntityCreationError, setCheckEntityCreationError] =
    useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rehireId, setRehireId] = useState(null);
  const [rehireLevel, setRehireLevel] = useState(null);
  const [contactInfoRequired, setContactInfoRequired] = useState(false);
  const [isW2Employee, setIsW2Employee] = useState(false);

  const contactSectionRef = useRef();
  const onboardingSectionRef = useRef();

  const [showAddEmployee, setShowAddEmployee] = useState(
    hasEmployees || calledFrom === 'QSG'
  );
  const [user, setUser] = useState();
  const [formWithStepsCurrentStep, setFormWithStepsCurrentStep] = useState(1);

  // We need to keep these values in the local component state, because
  // we are outside of the main Formik form in the 2nd step.
  const [stepOneFormikValues, setStepOneFormikValues] = useState({});
  const [stepOneCreateEmployeePayload, setStepOneCreateEmployeePayload] =
    useState({});

  const showFormWithSteps =
    payrollGrowthBenefitsAddTeamDrawerEnabled && isPayrollEnrolled;

  const showStepper = useMemo(
    () =>
      showFormWithSteps &&
      SHOW_STEPPER_PAYROLL_STATUSES.includes(payrollState) &&
      isW2Employee,
    [showFormWithSteps, payrollState, isW2Employee]
  );

  useEffect(() => {
    onFetchCompanyRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchPayrollInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    trackUxEvent({
      productArea: PRODUCT_AREAS.TEAM,
      eventCategory: EVENT_CATEGORIES.ADD_TEAM_DRAWER,
      eventAction: EVENT_ACTIONS.ADD_TEAM_DRAWER_SHOWN,
      properties: {
        form_type: hasEmployees
          ? FORM_TYPES.ADD_TEAM_INDIVIDUALLY
          : FORM_TYPES.MULTIPLE_OPTIONS,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResetContactInfoRequired = useCallback(() => {
    if (contactInfoRequired) setContactInfoRequired(false);
  }, [contactInfoRequired]);

  const successModalTitle = useCallback(
    fullName => {
      let action;

      if (isAdding) {
        action = 'added';
      } else if (isEditing) {
        action = 'updated';
      } else {
        action = 'rehired';
      }

      return toI18n(`${I18N_PATH}.${action}`, {
        props: { name: fullName },
      });
    },
    [isAdding, isEditing]
  );

  const doAction = useCallback(
    payload => {
      let actionFunction;
      let actionPayload;

      if (isAdding) {
        actionFunction = onAddTeamMember;
        actionPayload = payload;
      } else if (isEditing) {
        actionFunction = onUpdateTeamMember;
        actionPayload = { id: currentTeamMember.id, payload };
      } else {
        actionFunction = onRehireTeamMember;
        actionPayload = { id: rehireId, payload };
      }

      return actionFunction(actionPayload)
        .then(response => {
          if (response.error || response.errors) {
            setIsLoading(false);
            if (response.error) {
              flashError(response.error);
            } else {
              flashError(response.errors.join('. '));
            }
          }
          return response;
        })
        .catch(err => {
          datadogLogError({
            message: err,
            context: { currentTeamMember, locationId },
          });
          setIsLoading(false);
          flashError(toI18n('errors.generic'));
        });
    },
    [
      currentTeamMember,
      isAdding,
      isEditing,
      locationId,
      onAddTeamMember,
      onRehireTeamMember,
      onUpdateTeamMember,
      rehireId,
    ]
  );

  const handleCreatePayrollCheckEntity = useCallback(
    ({ userId, entityType }) =>
      onCreatePayrollCheckEntity({ userId, entityType })
        .then(response => {
          if (response.payload.errors) {
            return setCheckEntityCreationError(response.payload.errors);
          }
          return response.payload;
        })
        .catch(() => {
          const genericErrorMessage = toI18n('errors.generic');
          flashError(genericErrorMessage);
          setCheckEntityCreationError(genericErrorMessage);
        })
        .finally(() => {
          setIsLoading(false);
        }),
    [onCreatePayrollCheckEntity]
  );

  const handleCloseDrawer = useCallback(
    values => {
      if (values && formHasContent(values)) return setIsExiting(true);

      onCloseDrawer();
    },
    [onCloseDrawer]
  );

  const openSuccessModalAndCloseDrawer = useCallback(
    ({ id, fullName, firstName, payload, values }) => {
      onOpenSuccessModal({
        userId: id,
        title: successModalTitle(fullName),
        firstName,
        messageKey: getMessageKey(payload),
        isPayrollEnrolled,
        payrollOnboardingOptions: payrollOnboardingOptions(values.onboarding),
        onCloseDrawerCallback,
        isPayrollSetupDone:
          values.onboarding === ONBOARDING_OPTIONS.INVITE_AND_PACKET,
      });

      handleCloseDrawer();
    },
    [
      onOpenSuccessModal,
      successModalTitle,
      isPayrollEnrolled,
      onCloseDrawerCallback,
      handleCloseDrawer,
    ]
  );

  const handleAddTeamMember = useCallback(
    values => {
      setIsLoading(true);
      const payload = createEmployeePayload(
        values,
        isAdding,
        locationId,
        isAIOLocation,
        isPayrollEnrolled
      );

      const isAddingAndHasInputError =
        isAdding && (hasEmailError || hasPhoneError);
      const nothingRightNow = showFormWithSteps && payload.nothing_right_now;
      const hasContactInfoRequiredError =
        (payload.send_onboarding_packet || payload.send_sms) &&
        !Boolean(payload.user.email || payload.user.phone) &&
        !nothingRightNow;

      if (hasContactInfoRequiredError) {
        setContactInfoRequired(true);
        try {
          onboardingSectionRef.current.scrollIntoView(SCROLL_BEHAVIOR);
        } catch {
          contactSectionRef.current.scrollIntoView(SCROLL_BEHAVIOR);
        }
      }

      if (isAddingAndHasInputError) {
        contactSectionRef.current.scrollIntoView(SCROLL_BEHAVIOR);
      }

      if (isAddingAndHasInputError || hasContactInfoRequiredError) {
        getUxTracking(
          values,
          isAdding,
          isAIOLocation,
          currentLocationTierId,
          RESULT.FAIL,
          hasContactInfoRequiredError,
          isPayrollEnrolled
        );
        return setIsLoading(false);
      }

      return doAction(payload)
        .then(response => {
          if (response.error) throw new Error();

          if (isAdding) onIncrementUserCount(response.payload);

          getUxTracking(
            values,
            isAdding,
            isAIOLocation,
            currentLocationTierId,
            RESULT.SUCCESS,
            false,
            isPayrollEnrolled
          );
          const {
            first_name: firstName,
            last_name: lastName,
            id,
          } = response.payload;

          const fullName = lastName
            ? capitalize(firstName).concat(' ', capitalize(lastName))
            : capitalize(firstName);

          if (showFormWithSteps && formWithStepsCurrentStep === 1) {
            handleCreatePayrollCheckEntity({
              userId: id,
              entityType: values.jobTaxClassification.startsWith('w2')
                ? 'employee'
                : 'contractor',
            }).then(jobDetailsTabData => {
              setIsLoading(false);
              if (checkEntityCreationError) {
                document
                  .getElementById('add-team-member-step-one')
                  ?.scrollIntoView({ behavior: 'smooth' });
                return;
              }

              const {
                show_post_tax_deductions,
                show_benefits_widget,
                show_recurring_earnings_and_reimbursements,
              } = jobDetailsTabData || {};

              if (
                show_post_tax_deductions ||
                show_benefits_widget ||
                show_recurring_earnings_and_reimbursements
              ) {
                const fullUserData = fromJS(jobDetailsTabData).merge(
                  fromJS(response.payload)
                );
                setUser(fullUserData);
                setFormWithStepsCurrentStep(2);
                setStepOneFormikValues(values);
                setStepOneCreateEmployeePayload(payload);
                setIsLoading(false);
              } else {
                openSuccessModalAndCloseDrawer({
                  id,
                  fullName,
                  firstName,
                  payload,
                  values,
                });
              }
            });
          } else {
            openSuccessModalAndCloseDrawer({
              id,
              fullName,
              firstName,
              payload,
              values,
            });
          }
        })
        .catch(() => {
          setIsLoading(false);
          getUxTracking(
            values,
            isAdding,
            isAIOLocation,
            currentLocationTierId,
            RESULT.FAIL,
            hasContactInfoRequiredError,
            isPayrollEnrolled
          );
          flashError(toI18n('errors.generic'));
        });
    },
    [
      isAdding,
      locationId,
      isAIOLocation,
      isPayrollEnrolled,
      hasEmailError,
      hasPhoneError,
      showFormWithSteps,
      doAction,
      currentLocationTierId,
      onIncrementUserCount,
      formWithStepsCurrentStep,
      handleCreatePayrollCheckEntity,
      checkEntityCreationError,
      openSuccessModalAndCloseDrawer,
    ]
  );

  const handleCloseDrawerOnSecondStep = useCallback(() => {
    openSuccessModalAndCloseDrawer({
      id: user.get('id'),
      fullName: user.get('full_name'),
      firstName: user.get('first_name'),
      payload: stepOneCreateEmployeePayload,
      values: stepOneFormikValues,
    });
  }, [
    openSuccessModalAndCloseDrawer,
    stepOneCreateEmployeePayload,
    stepOneFormikValues,
    user,
  ]);

  const handleCloseExitDialog = useCallback(() => {
    setIsExiting(false);
  }, []);

  const handleExitAddTeamDrawer = useCallback(() => {
    handleCloseDrawer();

    setIsExiting(false);
  }, [handleCloseDrawer]);

  const handleClickPosImport = useCallback(() => {
    trackUxEvent({
      productArea: PRODUCT_AREAS.TEAM,
      eventCategory: EVENT_CATEGORIES.BULK_ACTIONS_DRAWER,
      eventAction: EVENT_ACTIONS.PARTNER_POS_IMPORT_CLICKED,
      properties: {
        source: SOURCE.ADD_TEAM_DRAWER,
      },
    });
    handleCloseDrawer();
    if (isSyncPOSPartner) {
      return browserHistory.push(importEmployeesRoute('pos'));
    }
    return onShowSyncEmployeesModal(partner, true);
  }, [handleCloseDrawer, isSyncPOSPartner, onShowSyncEmployeesModal, partner]);

  const handleClickPayrollImport = useCallback(() => {
    trackUxEvent({
      productArea: PRODUCT_AREAS.TEAM,
      eventCategory: EVENT_CATEGORIES.BULK_ACTIONS_DRAWER,
      eventAction: EVENT_ACTIONS.PARTNER_PAYROLL_IMPORT_CLICKED,
      properties: {
        source: SOURCE.ADD_TEAM_DRAWER,
      },
    });
    handleCloseDrawer();
    if (isSyncPayrollPartner) {
      return browserHistory.push(importEmployeesRoute('payroll'));
    }
    return onShowSyncEmployeesModal(payrollProvider.get('key'), false);
  }, [
    handleCloseDrawer,
    isSyncPayrollPartner,
    onShowSyncEmployeesModal,
    payrollProvider,
  ]);

  const handleClose = useCallback(() => {
    onCloseToast();
  }, [onCloseToast]);

  const showImportPosCTA = isAdding && isIntegratedPOSPartner;
  const showImportPayrollCTA =
    isAdding && !isIntegratedPOSPartner && isIntegratedPayrollPartner;
  const formFillOutRecommended = !(
    isIntegratedPOSPartner || isIntegratedPayrollPartner
  );

  const AddTeamDrawerHeader = props => (
    <DrawerHeader
      {...props}
      isPayrollEnrolled={isPayrollEnrolled}
      showStepper={showFormWithSteps && showStepper}
      activeIndex={formWithStepsCurrentStep}
      formSteps={['The basics', 'Benefits & Deductions']}
    />
  );

  const AddTeamDrawerFooter = props => (
    <DrawerFooter
      {...props}
      currentLocationTierId={currentLocationTierId}
      isAdding={isAdding}
      isAIOLocation={isAIOLocation}
      isPayrollEnrolled={isPayrollEnrolled}
      showFormWithSteps={showFormWithSteps}
    />
  );

  const headerTitle = useMemo(() => {
    if (isEditing) return toI18n('new_team_drawer.edit');
    if (isAdding) return toI18n('new_team_drawer.add');

    return toI18n('new_team_drawer.rehire');
  }, [isEditing, isAdding]);

  const footerButtonText = useMemo(() => {
    if (isEditing) return toI18n('new_team_drawer.edit_button_text');
    if (isAdding) {
      if (showFormWithSteps && showStepper) {
        return toI18n(
          `new_team_drawer.add_button_text_with_steps.${
            formWithStepsCurrentStep === 1 ? 'one' : 'two'
          }`
        );
      }
      return toI18n('new_team_drawer.add_button_text');
    }

    return toI18n('new_team_drawer.rehire_button_text');
  }, [
    isEditing,
    isAdding,
    showFormWithSteps,
    formWithStepsCurrentStep,
    showStepper,
  ]);

  const handleShowAddEmployee = useCallback(() => {
    trackUxEvent({
      productArea: PRODUCT_AREAS.TEAM,
      eventCategory: EVENT_CATEGORIES.ADD_TEAM_DRAWER,
      eventAction: EVENT_ACTIONS.BUTTON_CLICKED,
    });
    setShowAddEmployee(true);
  }, []);

  const handleShowBulkAddModal = useCallback(() => {
    trackUxEvent({
      productArea: PRODUCT_AREAS.TEAM,
      eventCategory: EVENT_CATEGORIES.ADD_TEAM_DRAWER,
      eventAction: EVENT_ACTIONS.BULK_ADD_CLICKED,
    });
    handleCloseDrawer();
    onShowBulkAddModal();
  }, [handleCloseDrawer, onShowBulkAddModal]);

  const handlePosImport = useCallback(() => {
    trackUxEvent({
      productArea: PRODUCT_AREAS.TEAM,
      eventCategory: EVENT_CATEGORIES.ADD_TEAM_DRAWER,
      eventAction: EVENT_ACTIONS.PARTNER_ADD_CLICKED,
    });

    handleCloseDrawer();
    return isIntegratedPOSPartner
      ? handleClickPosImport()
      : onShowConnectPosModal();
  }, [
    handleCloseDrawer,
    handleClickPosImport,
    isIntegratedPOSPartner,
    onShowConnectPosModal,
  ]);

  const AddEmployeeFormComponent = useMemo(() => {
    if (showFormWithSteps) {
      return formWithStepsCurrentStep === 1
        ? AddEmployeeFormStepOne
        : AddEmployeeForm;
    }

    return AddEmployeeForm;
  }, [showFormWithSteps, formWithStepsCurrentStep]);

  if (showFormWithSteps && formWithStepsCurrentStep === 2) {
    return (
      <BottomDrawer
        top={37}
        animations={animations}
        footerActionIsLoading={isLoading}
        // When closing the drawer in the 2nd step we can safely assume
        // the user was already created and all its values saved.
        onCancelClick={handleCloseDrawerOnSecondStep}
        headerComponent={AddTeamDrawerHeader}
        headerTitle={headerTitle}
        footerButtonText={`new_team_drawer.add_button_text_with_steps.${
          formWithStepsCurrentStep === 1 ? 'one' : 'two'
        }`}
        onClickFooterAction={handleCloseDrawerOnSecondStep}
        maxw="1440px"
      >
        {() => (
          <Box maxw={800} m="24px auto" p={20} pb={24} className={cx()}>
            <AddEmployeeFormStepTwo
              updateUserInfo={updateUserInfo}
              user={user}
              showFormWithSteps={showFormWithSteps}
            />
          </Box>
        )}
      </BottomDrawer>
    );
  }

  return (
    <>
      <Formik
        validationSchema={ADD_EMPLOYEE_FORM_SCHEMA}
        initialValues={initialValues(
          isPayrollEnrolled,
          isDefaultManager,
          currentTeamMember
        )}
        onSubmit={handleAddTeamMember}
      >
        {({ values }) => (
          <Form style={{ margin: 0 }}>
            <BottomDrawer
              top={37}
              animations={animations}
              footerActionIsLoading={isLoading}
              onCancelClick={partial(handleCloseDrawer, values)}
              headerComponent={AddTeamDrawerHeader}
              footerComponent={AddTeamDrawerFooter}
              headerTitle={headerTitle}
              footerButtonText={showAddEmployee ? footerButtonText : null}
              maxw="1440px"
            >
              {() => {
                const taxClassification = values.jobTaxClassification;

                if (taxClassification) {
                  // We use this as one of the values that will
                  // define if we must show the stepper or not.
                  setIsW2Employee(
                    taxClassification === 'w2-employee' ||
                      taxClassification === '1099-business'
                  );
                }

                return (
                  <Box
                    maxw={776}
                    bradiuss
                    m="24px auto"
                    p={20}
                    pb={24}
                    bgcolor={showFormWithSteps ? null : 'mono0'}
                    className={cx()}
                  >
                    {showImportPosCTA && (
                      <Box hcenter column mb={24}>
                        <ImportContainerCTA
                          className="import-pos"
                          handleClick={handleClickPosImport}
                          importModal="import_pos_modal"
                          partner={partner}
                        />
                      </Box>
                    )}
                    {showImportPayrollCTA && (
                      <Box hcenter column mb={24}>
                        <ImportContainerCTA
                          className="import-payroll"
                          handleClick={handleClickPayrollImport}
                          importModal="import_payroll_modal"
                          partner={payrollProvider.get('name')}
                        />
                      </Box>
                    )}
                    {isOpen && (
                      <>
                        {hasEmployees || showAddEmployee ? (
                          <AddEmployeeFormComponent
                            contactInfoRequired={contactInfoRequired}
                            contactSectionRef={contactSectionRef}
                            formFilloutRecommended={formFillOutRecommended}
                            isAIOLocation={isAIOLocation}
                            isPayrollEnrolled={isPayrollEnrolled}
                            onResetContactInfoRequired={
                              handleResetContactInfoRequired
                            }
                            onSetHasEmailError={setHasEmailError}
                            onSetHasPhoneError={setHasPhoneError}
                            onSetIsAdding={setIsAdding}
                            onSetRehireId={setRehireId}
                            onboardingSectionRef={onboardingSectionRef}
                            onSetRehireLevel={setRehireLevel}
                            rehireLevel={rehireLevel}
                            currentTeamMember={currentTeamMember}
                            updateUserInfo={updateUserInfo}
                            user={user}
                            showFormWithSteps={showFormWithSteps}
                            checkEntityCreationError={checkEntityCreationError}
                            setCheckEntityCreationError={
                              setCheckEntityCreationError
                            }
                          />
                        ) : (
                          <EmptyRosterOptions
                            handleClickPosImport={handlePosImport}
                            handleShowAddEmployee={handleShowAddEmployee}
                            handleShowBulkAddModal={handleShowBulkAddModal}
                          />
                        )}
                      </>
                    )}
                    <Toast
                      onClose={handleClose}
                      open={toastIsOpen}
                      variant="success"
                    >
                      {toI18n('new_team_drawer.new_role_added', {
                        props: {
                          new_role: roleValue,
                        },
                      })}
                    </Toast>
                  </Box>
                );
              }}
            </BottomDrawer>
          </Form>
        )}
      </Formik>
      <ExitModal
        onDiscard={handleExitAddTeamDrawer}
        onClose={handleCloseExitDialog}
        isOpen={isExiting}
        title={toI18n(
          `new_team_drawer.exit_modal.title.${isEditing ? 'edit' : 'add'}`
        )}
        description={toI18n('new_team_drawer.exit_modal.description')}
        continueButton={toI18n(
          `new_team_drawer.exit_modal.continue.${isEditing ? 'edit' : 'add'}`
        )}
        discardButton={toI18n('new_team_drawer.exit_modal.discard')}
      />
    </>
  );
};

AddTeamDrawerContainer.propTypes = {
  animations: PropTypes.object,
  currentLocationTierId: PropTypes.number,
  isAIOLocation: PropTypes.bool,
  isIntegratedPOSPartner: PropTypes.bool,
  isIntegratedPayrollPartner: PropTypes.bool,
  isOpen: PropTypes.bool,
  isSyncPOSPartner: PropTypes.bool,
  isSyncPayrollPartner: PropTypes.bool,
  locationId: PropTypes.number,
  onAddTeamMember: PropTypes.func.isRequired,
  onCloseDrawer: PropTypes.func.isRequired,
  onCloseToast: PropTypes.func,
  onCreatePayrollCheckEntity: PropTypes.func.isRequired,
  onFetchCompanyRoles: PropTypes.func,
  onOpenSuccessModal: PropTypes.func.isRequired,
  onRehireTeamMember: PropTypes.func.isRequired,
  onShowSyncEmployeesModal: PropTypes.func,
  partner: PropTypes.string,
  payrollProvider: ImmutablePropTypes.map,
  toastIsOpen: PropTypes.bool,
  toastProps: PropTypes.shape({
    roleValue: PropTypes.string,
  }),
};

export default connect(
  state => ({
    currentLocationTierId: getCurrentLocationTierId(state),
    isAIOLocation: getCurrentLocationTierName(state) === TIER_NAMES.ENTERPRISE,
    isIntegratedPOSPartner: getCurrentLocationIsIntegratedPOSPartner(state),
    isIntegratedPayrollPartner:
      getCurrentLocationIsIntegratedPayrollPartner(state),
    isOpen: selectDrawerIsOpen(state),
    isDefaultManager: getIsDefaultManager(state),
    isPayrollEnrolled: getIsPayrollEnrolled(state),
    isSyncPOSPartner: getCurrentLocationIsPOSForSyncPage(state),
    isSyncPayrollPartner: getCurrentLocationIsPayrollForSyncPage(state),
    locationId: getCurrentLocationId(state),
    onCloseDrawerCallback: selectOnCloseDrawerCallback(state),
    currentTeamMember: selectCurrentTeamMember(state),
    partner: getCurrentLocationPartner(state),
    payrollProvider: getCurrentLocationPayrollProvider(state),
    toastIsOpen: selectToastIsOpen(state),
    toastProps: selectToastProps(state),
    managersCanAccess: getHasManagersFeatureAccess(state),
    hasEmployees: selectActiveUsersCount(state) > 1,
    calledFrom: selectCalledFrom(state),
    payrollGrowthBenefitsAddTeamDrawerEnabled: getRolloutEnabled(
      state,
      'payroll_growth_benefits_add_team_drawer_jun25_pay13528'
    ),
    payrollState: getPayrollState(state),
  }),
  {
    onAddTeamMember: addTeamMember,
    onCloseDrawer: closeAddTeamDrawer,
    onCloseToast: closeToast,
    onCreatePayrollCheckEntity: createPayrollCheckEntity,
    onFetchCompanyRoles: fetchCompanyRoles,
    onIncrementUserCount: updateActiveUserCountByOne,
    onOpenSuccessModal: openSuccessModal,
    onUpdateTeamMember: updateTeamMember,
    onRehireTeamMember: rehireTeamMember,
    onShowConnectPosModal: showConnectPosModal,
    onShowSyncEmployeesModal: showSyncEmployeesModal,
    onShowBulkAddModal: showBulkAddModal,
    updateUserInfo: employeeActions.updateUserInfo,
    fetchPayrollInfo: getFetchPayrollInfo,
  }
)(
  withUxRoot({
    productArea: PRODUCT_AREAS.TEAM,
    eventCategory: EVENT_CATEGORIES.ADD_TEAM_DRAWER,
  })(AddTeamDrawerContainer)
);
