import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Box from 'fe-design-base/atoms/Box';
import { List } from 'immutable';

import { cxHelpers } from 'util/className';
const { cx } = cxHelpers('PaymentMethodModule');

import CircularInfinite from 'fe-design-base/molecules/Loaders/CircularInfinite';

import { getCurrentCompanyId, getCurrentLocation } from 'selectors/session';

import {
  getCreditCards,
  selectFetchCreditCardsPending,
  selectSalesTaxInCents,
} from 'features/biller/implementations/stripe/selectors';
import { fetchCreditCards } from 'features/biller/implementations/stripe/thunks';
import {
  selectCanViewPaymentMethodModule,
  selectSalesTaxPending,
} from 'features/biller/selectors';
import { submitInitialSalesTaxTotals } from 'features/biller/thunks';

import { centsToDollar } from 'util/formatter';

import { PURCHASE_MODULE_OPTIONS } from '../PaymentMethodModule/constants';
import { PaymentMethodModuleNoPermissionView } from '../PaymentMethodModule/PaymentMethodModuleNoPermissionView';

import { Price, PurchaseMethodModuleView } from './PurchaseMethodModuleView';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PaymentMethodModuleWrapper = ({ children }: any) => {
  const stripePromise =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.stripeClient && loadStripe(window.stripeClient.publishableKey);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Elements stripe={stripePromise} options={PURCHASE_MODULE_OPTIONS}>
      {children}
    </Elements>
  );
};

export const PurchaseMethodModule = ({
  canViewModule,
  creditCards,
  price,
  onFetchCreditCards,
  onSuccess,
  onLoading,
  fetchCreditCardsPending,
  salesTaxInCents,
  isSalesTaxPending,
  onSubmitInitialSalesTaxTotals,
  currentLocation,
  companyId,
}: PurchaseMethodModuleProps) => {
  useEffect(() => {
    onFetchCreditCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onSubmitInitialSalesTaxTotals({
      location: currentLocation,
      companyId,
      taxableAmount: centsToDollar(price.amountInCents),
    });
  }, [
    currentLocation,
    companyId,
    price.amountInCents,
    onSubmitInitialSalesTaxTotals,
  ]);

  if (fetchCreditCardsPending || isSalesTaxPending) {
    return (
      <Box hcenter vcenter mt={32}>
        <CircularInfinite variant="dark" size="large" />
      </Box>
    );
  }

  return (
    <Box className={cx()}>
      {canViewModule ? (
        <PaymentMethodModuleWrapper>
          <PurchaseMethodModuleView
            creditCards={creditCards}
            price={price}
            salesTaxInCents={salesTaxInCents}
            onLoading={onLoading}
            onSuccess={onSuccess}
          />
        </PaymentMethodModuleWrapper>
      ) : (
        <PaymentMethodModuleNoPermissionView />
      )}
    </Box>
  );
};

export type PurchaseMethodModuleProps = {
  canViewModule: boolean;
  fetchCreditCardsPending: boolean;
  price: Price;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  creditCards: List<unknown>;
  onFetchCreditCards: (...args: unknown[]) => void;
  onSubmitInitialSalesTaxTotals: (...args: unknown[]) => void;
  onSuccess: (...args: unknown[]) => void;
  onLoading: (isLoading: boolean) => void;
  currentLocation: Record<string, unknown>;
  companyId: number;
  isSalesTaxPending: boolean;
  salesTaxInCents: number;
};

export default connect(
  state => ({
    creditCards: getCreditCards(state),
    canViewModule: selectCanViewPaymentMethodModule(state),
    fetchCreditCardsPending: selectFetchCreditCardsPending(state),
    salesTaxInCents: selectSalesTaxInCents(state),
    isSalesTaxPending: selectSalesTaxPending(state),
    currentLocation: getCurrentLocation(state),
    companyId: getCurrentCompanyId(state),
  }),
  {
    onFetchCreditCards: fetchCreditCards,
    onSubmitInitialSalesTaxTotals: submitInitialSalesTaxTotals,
  }
)(PurchaseMethodModule);
