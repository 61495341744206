import { createSlice } from '@reduxjs/toolkit';

import { INITIAL_STATE, TASK_MANAGER_SLICE_NAME } from './constants';

const slice = createSlice({
  name: TASK_MANAGER_SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: {},
});

const { reducer } = slice;

export default reducer;
