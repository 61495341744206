// REFERENCE: http://revalidate.jeremyfairbank.com/

import { each, isArray } from 'lodash';
import {
  combineValidators,
  composeValidators,
  createValidator,
  isNumeric,
  isRequired,
  matchesField,
} from 'revalidate';
import validate from 'validate.js';

import { EIN_REGEX, SSN_REGEX } from 'util/constants';
import { moment } from 'util/dateTime';
import { toI18n } from 'util/i18n';
import { pick } from 'util/objectMethods';

const US_ZIP_REGEX = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/;
const CA_ZIP_REGEX = /^([A-Z][0-9][A-Z])\s*([0-9][A-Z][0-9])$/;
const UK_ZIP_REGEX = /^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i;

// eslint-disable-next-line max-len
export const US_FORMAT = /(^\d{5}$)|(^\d{5}-\d{4}$)/; // 12345 or 123456789 or 12345-6789
export const CANADA_FORMAT =
  // eslint-disable-next-line max-len
  /([ABCEGHJKLMNPRSTVXY]\d)([ABCEGHJKLMNPRSTVWXYZ]\d){2}/i; // A1A A1A or A1A1A1 or a1a1a1 or a1a 1a1
export const UK_FORMAT =
  // eslint-disable-next-line max-len
  /(?:[A-Za-z]\d ?\d[A-Za-z]{2})|(?:[A-Za-z][A-Za-z\d]\d ?\d[A-Za-z]{2})|(?:[A-Za-z]{2}\d{2} ?\d[A-Za-z]{2})|(?:[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]{2})|(?:[A-Za-z]{2}\d[A-Za-z] ?\d[A-Za-z]{2})/;

import { isInUS } from 'components/forms/TextField/util';

export const isValidPositiveIntegerOrEmpty = value =>
  !validate.single(value, {
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
    },
    format: /^\S*$/,
  }) || value === '';

export const isValidEmail = createValidator(
  message => value => {
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(value)) {
      return message;
    }
    return undefined;
  },
  'Invalid email address'
);

export const isValidSSN = createValidator(
  message => value => {
    if (!SSN_REGEX.test(value)) {
      return message;
    }
    return undefined;
  },
  'SSN must be 9 digits'
);

export const isValidEin = createValidator(
  message => value => {
    if (!EIN_REGEX.test(value)) {
      return message;
    }
    return undefined;
  },
  'EIN must be 9 digits'
);

// Assumes input mask (XXX) XXX-XXXX. Tests whether string contains ten digits.
export const isValidPhone = createValidator(
  message => value => {
    if (value && value.replace(/[^\d]/g, '').length !== 10 && isInUS()) {
      return message;
    }
    return undefined;
  },
  'Phone numbers must be ten digits'
);

// Check that input is either a valid phone number or email
export const isValidContactInfo = createValidator(
  message => value => {
    if (
      value &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(value) &&
      value &&
      value.replace(/[^\d]/g, '').length !== 10
    ) {
      return message;
    }
    return undefined;
  },
  toI18n('add_team.errors.contact_info')
);

// Assumes input mask XXXXXX. Tests whether string contains ten digits.
export const isValidPIN = createValidator(
  message => value => {
    if (value && value.replace(/[^\d]/g, '').length !== 6) {
      return message;
    }
    return undefined;
  },
  'PIN must be six digits'
);

export const isValidDatePattern = ({ monthYearOnly }) =>
  createValidator(
    message => value => {
      const regex = monthYearOnly ? /^\d{2}\/\d{4}$/ : /^\d{2}\/\d{2}\/\d{4}$/;
      return value && !regex.test(value) ? message : undefined;
    },
    `Dates must take the pattern MM${monthYearOnly ? '' : '/DD'}/YYYY`
  );

export const isActualDate = ({ monthYearOnly }) =>
  createValidator(
    message => value => {
      const format = monthYearOnly ? 'MM/YYYY' : 'MM/DD/YYYY';
      return value && !moment(value, format).isValid() ? message : undefined;
    },
    'Must be a real date'
  );

export const isValidDate = (config = {}) =>
  composeValidators(isValidDatePattern(config), isActualDate(config));

export const isBeforeDateMY = (endDateField, endDateFieldLabel) =>
  createValidator(
    message => (value, allValues) => {
      const endDateValue = allValues[endDateField];

      if (!value || !endDateValue) return;

      const startDate = moment(value, 'MM/YYYY');
      const endDate = moment(endDateValue, 'MM/YYYY');

      if (!endDate.isAfter(startDate)) {
        return message;
      }
    },
    field =>
      toI18n('errors.dates.start_before_end', {
        props: { end: endDateFieldLabel, start: field },
      })
  );

// This function expects a string that is in the format of 'MM/DD/YYY' or 'MM/YYYY'
export const isBetween1900AndNowMY = createValidator(
  message => value => {
    if (!value) return;

    const startAt = moment(new Date('1/1/1900')).format('MM/DD/YYYY');
    const endAt = moment().format('MM/DD/YYYY');

    if (!moment(value, ['MM/DD/YYYY', 'MM/YYYY']).isBetween(startAt, endAt)) {
      return message;
    }
  },
  toI18n('errors.dates.between_1900_and_now')
);

export { isRequired };

// Via validate.js
validate.validators.presence.message = toI18n(
  'onboarding.validations.required'
);

validate.validators.hireDateRange = value => {
  if (!value) {
    return null;
  }

  const year = moment(value, 'MM/DD/YYYY').year();
  if (year >= 1900 && year <= 2100) {
    return null;
  }

  return toI18n('team.employee_profile.access_wages.validations.date');
};

export const buildStateValidator =
  validators =>
  (state, ...fieldNames) => {
    const validateWith = fieldNames.length
      ? pick(validators, fieldNames)
      : validators;
    const errors = validate(state, validateWith) || {};

    each(validateWith, (_v, k) => {
      const error = errors[k];
      errors[k] = error;
      if (error && isArray(error)) {
        errors[k] = error[0];
      }
    });

    return { ...state.errors, ...errors };
  };

export const isValidZip = zip =>
  US_ZIP_REGEX.test(zip) || CA_ZIP_REGEX.test(zip) || UK_ZIP_REGEX.test(zip);

export const URL_REGEX =
  // eslint-disable-next-line max-len
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/;

export const PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[a-zA-Z])(.{8,})$/;
// eslint-disable-next-line max-len
export const PASSWORD_REGEX_V3 = /^(?=.*[0-9])(?=.*[a-zA-Z])(.{8,})$/;
export const PASSWORD_MESSAGE = toI18n('onboarding.validations.password');
export const PASSWORD_VALIDATOR = {
  presence: {
    allowEmpty: false,
  },
  format: {
    pattern: PASSWORD_REGEX,
    message: PASSWORD_MESSAGE,
  },
};

// Two or more words
export const FULL_NAME_REGEX = /\w+\s\w+[\s?\w+]*/;
export const PHONE_REGEX = /\d{10}/;
export const PHONE_EXTENDED_REGEX = /^\d{3}-?\d{3}-?\d{4,9}$/;
export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;

// To use in EmployeeFormField or FormField components validate attribute
// using revalidate.js validators
export const zipValidator = composeValidators(
  isRequired,
  createValidator(
    message => value => {
      if (value && !isValidZip(value)) {
        return message;
      }
      return undefined;
    },
    field => `Invalid ${field}`
  )
);

export const isValidRoutingNumber = createValidator(
  message => routingNumber => {
    if (routingNumber) {
      if (routingNumber.length !== 9) return message;
      let checksum = 0;
      for (let i = 0; i < routingNumber.length; i += 3) {
        checksum +=
          parseInt(routingNumber.charAt(i), 10) * 3 +
          parseInt(routingNumber.charAt(i + 1), 10) * 7 +
          parseInt(routingNumber.charAt(i + 2), 10);
      }

      if (checksum === 0 || checksum % 10 !== 0) return message;
      return undefined;
    }
    return message;
  },
  field => field
);

export const isValidCompanyDocument = createValidator(
  message => document => {
    if (document) {
      const fileSizeInMB = document.size / 1024 / 1024;
      if (fileSizeInMB > 6) return message;
      return undefined;
    }
    return message;
  },
  field => field
);

const sharedBankAccountValidators = {
  decrypted_routing_number: composeValidators(
    isRequired(
      toI18n('team.employee_profile.banking_information.routing_number')
    ),
    isValidRoutingNumber(
      toI18n(
        'team.employee_profile.banking_information.invalid_field_messages.routing_number'
      )
    )
  )('decrypted_routing_number'),
  decrypted_account_number: composeValidators(
    isRequired(
      toI18n('team.employee_profile.banking_information.bank_account_number')
    ),
    isNumeric(
      toI18n('team.employee_profile.banking_information.bank_account_number')
    )
  )('decrypted_account_number'),
  decrypted_reentered_account_number: composeValidators(
    isRequired(
      toI18n(
        'team.employee_profile.banking_information.reenter_bank_account_number'
      )
    ),
    matchesField('decrypted_account_number')({
      message: toI18n(
        'team.employee_profile.banking_information.invalid_bank_account_numbers'
      ),
    })
  )('decrypted_reentered_account_number'),
};

// Deprecated and should be removed after confirming it's no longer in use
export const bankAccountDataValidator = combineValidators({
  ...sharedBankAccountValidators,
  bank_account_type: isRequired(
    toI18n('team.employee_profile.banking_information.account_type')
  ),
});

export const companyBankAccountDataValidator =
  manualBankAccountSkipDocUpload => {
    const docValidation = !manualBankAccountSkipDocUpload
      ? {
          document_type: isRequired(
            toI18n(
              'payroll.connect_bank_account_modal.manual.fields.document_type'
            )
          ),
          file: composeValidators(
            isRequired(
              toI18n(
                'payroll.connect_bank_account_modal.manual.fields.document'
              )
            ),
            isValidCompanyDocument(
              toI18n('dropzone.validations.upload_size', { props: { size: 6 } })
            )
          )('file'),
        }
      : {};

    return combineValidators({
      ...sharedBankAccountValidators,
      ...docValidation,
    });
  };
