/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Box from 'fe-design-base/atoms/Box';
import IconButton from 'fe-design-base/molecules/IconButton';
import { compose } from 'redux';

import { getCurrentLocationName } from 'selectors/session';

import { Price } from 'features/biller/implementations/stripe/PurchaseMethodModule/PurchaseMethodModuleView';
import {
  fetchProductTipManager as getFetchProductTipManager,
  fetchTipManagerSubscriptions as getFetchTipManagerSubscriptions,
} from 'features/managePlan/actions';
import { selectProductTipManagerPrice } from 'features/managePlan/selectors';
import { getCurrentPayrollPeriod } from 'features/settings/TipManagerSettingView/selectors';
import { fetchLocationTipPolicies as getFetchLocationTipPolicies } from 'features/settings/TipManagerSettingView/thunks';

import Drawer from 'components/Drawer';

import { cxHelpers } from 'util/className';
import { EVENT_CATEGORIES, PRODUCT_AREAS } from 'util/tracking_constants';
import { useExtendUx, withUxRoot } from 'util/uxEvents';

import { InitialViewKeyType } from './constants';
import { selectInitialViewKey } from './selectors';
import { closeTipManagerCheckoutDrawer as getCloseTipManagerCheckoutDrawer } from './slice';
import * as views from './views';

const { cx } = cxHelpers('TipManagerCheckoutDrawer');
export interface TipManagerCheckoutDrawerProps {
  closeTipManagerCheckoutDrawer: () => any;
  initialViewKey?: InitialViewKeyType;
  fetchLocationTipPolicies: () => void;
  fetchProductTipManager: () => void;
  fetchTipManagerSubscriptions: () => void;
  currentPayrollPeriod: Array<string>;
  currentLocationName: string;
  price: Price;
}

export const TipManagerCheckoutDrawer: React.FC<
  TipManagerCheckoutDrawerProps
> = ({
  closeTipManagerCheckoutDrawer,
  initialViewKey,
  fetchLocationTipPolicies,
  currentPayrollPeriod,
  currentLocationName,
  fetchProductTipManager,
  fetchTipManagerSubscriptions,
  price,
}) => {
  const extendUxEvent = useExtendUx();
  const [currentViewKey, setCurrentViewKey] = useState(initialViewKey);
  const [isLoading, setIsLoading] = useState(false);
  const CurrentView = views[currentViewKey as keyof typeof views];
  const payPeriodStartDate = currentPayrollPeriod?.[0] || '';
  const handleOnLoading = useCallback((loading: boolean) => {
    setIsLoading(loading);
  }, []);
  const handleOnSuccess = useCallback(() => {
    setCurrentViewKey('Success');
    fetchTipManagerSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchLocationTipPolicies();
    fetchProductTipManager();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    extendUxEvent({
      currentViewKey,
    });
  }, [currentViewKey, extendUxEvent]);

  return (
    <Drawer className={cx()} maxWidth="464px" open>
      <Box hright mt={16} mr={16}>
        <IconButton
          onClick={closeTipManagerCheckoutDrawer}
          icon="Close"
          size="small"
          uxElement="checkout_drawer.close_button"
        />
      </Box>
      <Box mt={12} ph={32}>
        <CurrentView
          onSuccess={handleOnSuccess}
          onLoading={handleOnLoading}
          isLoading={isLoading}
          payPeriodStartDate={payPeriodStartDate}
          closeDrawer={closeTipManagerCheckoutDrawer}
          locationName={currentLocationName}
          price={price}
        />
      </Box>
    </Drawer>
  );
};
export default compose(
  withUxRoot({
    productArea: PRODUCT_AREAS.GROWTH,
    eventCategory: EVENT_CATEGORIES.TIP_MANAGER_CHECKOUT_DRAWER,
  }),
  connect(
    state => ({
      initialViewKey: selectInitialViewKey(state),
      currentPayrollPeriod: getCurrentPayrollPeriod(state),
      currentLocationName: getCurrentLocationName(state),
      price: selectProductTipManagerPrice(state),
    }),
    {
      closeTipManagerCheckoutDrawer: getCloseTipManagerCheckoutDrawer,
      fetchLocationTipPolicies: getFetchLocationTipPolicies,
      fetchProductTipManager: getFetchProductTipManager,
      fetchTipManagerSubscriptions: getFetchTipManagerSubscriptions,
    }
  )
)(TipManagerCheckoutDrawer);
