import { Map, Set } from 'immutable';
import { createSelector } from 'reselect';

import { getApplicants, getLocationsIndex } from 'selectors/entities';
import { getModalType } from 'selectors/modals';

import { BANNER } from 'features/banners/constants';
import {
  CUSTOM_POS_NAMES,
  DASHBOARD_WIDGET_POS_OPTIONS,
} from 'features/dashboard/constants';
import { MODAL } from 'features/modals/constants';
import { TIMESHEETS_ROUTES } from 'features/nav/constants';
import * as tierConstants from 'features/tiers/constants';

import { toEntityId } from 'util/entities';
import { capitalizeFirstLetter } from 'util/formatter';
import { browserHistory } from 'util/router';
import { titleize } from 'util/strings';

export const getAccountIsHub = state => state.getIn(['session', 'is_hub']);

export const getPartnerJobPermissions = state =>
  state.getIn(['session', 'partnerJobPermissions']);

export const getEnableWebsockets = state =>
  state.getIn(['session', 'enable_websockets']);

export const getMessagingActivationEnabled = state =>
  state.getIn(['session', 'messagingActivation']);

export const getSalesforceMessagingEnabled = state =>
  state.getIn(['session', 'salesforceMessagingEnabled']);

export const getGoogleMapURL = state =>
  state.getIn(['session', 'googleMapURL']);
export const getStates = state => state.getIn(['session', 'states']);
export const getUSStates = state => state.getIn(['session', 'us_states']);
export const getPayrollUSStates = state =>
  state.getIn(['session', 'payroll_us_states']);
export const getCountries = state => state.getIn(['session', 'countries']);
export const getTimeZones = state => state.getIn(['session', 'timeZones']);
export const getWebview = state => state.getIn(['session', 'webview']);

export const getHiringManageApplicantsPath = state =>
  state.getIn(['session', 'hiring_manage_applicants_nav_path']);
export const getBusinessTypes = state =>
  state.getIn(['session', 'businessTypes']);
export const getBusinessTaxonomy = state =>
  state.getIn(['session', 'businessTaxonomy']);
export const getCurrentLocation = state =>
  state.getIn(['session', 'currentLocation']);

export const getCurrentLocationWithArchived = state =>
  state.getIn(['session', 'currentLocation', 'name'])
    ? state.getIn(['session', 'currentLocation'])
    : state.getIn(['session', 'currentArchivedLocation']);
export const getCurrentCompanyHasMultipleLocations = state =>
  state.getIn(['session', 'multipleLocations']);
export const getCurrentCompanyHasBasicLocation = state =>
  state.getIn(['session', 'enforcement', 'companyHasBasicLocation']);
export const getResetPasswordToken = state =>
  state.getIn(['session', 'resetPasswordToken']);
export const getOnboardSource = state =>
  state.getIn(['session', 'onboardSource']);
export const getTeamEmailAudiences = state =>
  state.getIn(['session', 'teamEmailAudiences']);
export const getTiers = state => state.getIn(['session', 'tiers']);
export const getShowPasswordResetModal = state =>
  state.getIn(['session', 'showPasswordResetModal']);
export const getAuthErrorMessage = state =>
  state.getIn(['session', 'authErrorMessage']);
export const getIsGodModing = state => state.getIn(['session', 'isGodModing']);
export const selectShowPayrollEoyBanner = state =>
  state.getIn(['session', 'monetization', 'showPayrollEoyBanner']);
export const getStarterKitPayrollPackage = state =>
  state.getIn(['session', 'monetization', 'starterKitPayrollPackage']);

export const getMfaUserEnabled = state =>
  state.getIn(['session', 'mfaUserEnabled']);

export const getMfaUserPhone = state =>
  state.getIn(['session', 'mfaUserPhone']);

export const getMfaUserEmail = state =>
  state.getIn(['session', 'mfaUserEmail']);

export const getGooglePlaceTypes = state =>
  state.getIn(['session', 'googlePlaceTypes']);

export const getTierPlus = createSelector(getTiers, tiers =>
  tiers.find(t => t.get('name') === 'plus')
);

const createStateOptions = states =>
  states.map(([label, value]) => ({ label, value }));

export const getStateOptions = createSelector(getStates, createStateOptions);

export const getUSStateAbbrOptions = createSelector(getUSStates, states =>
  states.map(([, value]) => ({ label: value, value }))
);

export const getPayrollUSStateAbbrOptions = createSelector(
  getPayrollUSStates,
  states => states.map(([, value]) => ({ label: value, value }))
);

export const getPayrollUSStateOptions = createSelector(
  getPayrollUSStates,
  createStateOptions
);

export const getUSStateOptions = createSelector(
  getUSStates,
  createStateOptions
);

export const getCountryOptions = createSelector(getCountries, countries =>
  countries.map(([label, value]) => ({ label, value }))
);

export const getBusinessTypeOptions = createSelector(
  getBusinessTypes,
  businessTypes =>
    businessTypes.map(value => ({ value, label: value })).toList()
);

export const getCurrentLocationArchived = state =>
  !!state.getIn(['session', 'currentArchivedLocation', 'id']);

const buildCurrentLocationSelector = key => state =>
  state.getIn(['session', 'currentLocation', key]) ||
  state.getIn(['session', 'currentArchivedLocation', key]);

export const getCurrentLocationId = buildCurrentLocationSelector('id');
export const getCurrentLocationName = buildCurrentLocationSelector('name');
export const getCurrentLocationCity = buildCurrentLocationSelector('city');
export const getCurrentLocationMtcDiscountEligible =
  buildCurrentLocationSelector('eligible_for_mobile_time_clock_discount');

export const getCurrentLocationTeamSize = buildCurrentLocationSelector(
  'employee_count_range'
);

export const getCurrentLocationIsCloverEmbedded =
  buildCurrentLocationSelector('is_clover_embedded');

export const getOnboardingResponses = buildCurrentLocationSelector(
  'onboarding_responses'
);

export const getHealthQuestionsReminderBeforeShift =
  buildCurrentLocationSelector('health_questions_reminder_before_shift');
export const getHealthQuestionsReminderEnabled = buildCurrentLocationSelector(
  'health_questions_reminder_enabled'
);
export const getHealthQuestionsWhen = buildCurrentLocationSelector(
  'health_questions_when'
);
export const getCurrentLocationStateName =
  buildCurrentLocationSelector('state');
export const getCurrentLocationPinLength =
  buildCurrentLocationSelector('pin_length');
export const getCurrentLocationCreatedAt =
  buildCurrentLocationSelector('created_at');
export const getCurrentLocationTierId = buildCurrentLocationSelector('tier_id');
export const hasPayrollSettings = createSelector(
  buildCurrentLocationSelector('payroll_info'),
  buildCurrentLocationSelector('payroll_period'),
  (payrollInfo, payrollPeriod) =>
    payrollPeriod !== undefined && payrollInfo !== undefined
);
export const getCurrentLocationPayrollPeriod =
  buildCurrentLocationSelector('payroll_period');

export const getCurrentLocationTierName = createSelector(
  getTiers,
  getCurrentLocationTierId,
  (tiers, currentLocationTierId) =>
    (tiers.find(tier => tier.get('id') === currentLocationTierId) || Map()).get(
      'name'
    )
);

export const getCurrentLocationActiveTrialEndDate =
  buildCurrentLocationSelector('activeTrialEndDate');

export const getCurrentLocationHasActiveSecureTrial =
  buildCurrentLocationSelector('hasActiveSecureTrial');

export const getCurrentLocationActiveSecureTrialEndDate =
  buildCurrentLocationSelector('activeSecureTrialEndDate');

export const getCurrentLocationActiveSecureTrialDaysLeft =
  buildCurrentLocationSelector('activeSecureTrialDaysLeft');

export const getCurrentLocationHasActiveTrial =
  buildCurrentLocationSelector('hasActiveTrial');

export const getCurrentLocationAioRetrial =
  buildCurrentLocationSelector('aio_retrial');

export const getCurrentLocationDevice =
  buildCurrentLocationSelector('has_device');

export const getCurrentLocationAioRetrialState = state =>
  getCurrentLocationAioRetrial(state).getIn(['state', 'aio_state']);

export const getCurrentLocationAioRetrialDaysLeft = state =>
  getCurrentLocationAioRetrial(state).get('days_left_in_trial');

export const getCurrentLocationPartnerName =
  buildCurrentLocationSelector('partner_name');

export const getStateName = createSelector(
  getStates,
  getCurrentLocationStateName,
  (states, state) => {
    const matchingState = states.find(
      val => val.get(0) === state || val.get(1) === state
    );
    return matchingState && matchingState.get(0);
  }
);

export const getMultiLocationTrialEnabled = state =>
  state.getIn(['session', 'currentLocation', 'multiLocationTrialEnabled']);

export const getSecondLocationTrialEnabled = state =>
  state.getIn(['session', 'currentLocation', 'secondLocationTrialEnabled']);

export const getClockInReminder = state =>
  state.getIn(['session', 'currentLocation', 'clock_in_reminder']);
export const getImportCreditTips = state =>
  state.getIn(['session', 'currentLocation', 'import_credit_tips']);
export const getServerBanking = state =>
  state.getIn(['session', 'currentLocation', 'server_banking']);
export const getOpenOrders = state =>
  state.getIn(['session', 'currentLocation', 'open_orders']);

export const getCurrentLocationEntity = createSelector(
  getCurrentLocationId,
  getLocationsIndex,
  (id, locationsIndex) => locationsIndex.get(toEntityId(id))
);

export const buildCurrentLocationPropSelector = propKey => state =>
  getCurrentLocationEntity(state) &&
  getCurrentLocationEntity(state).get(propKey);

export const getCurrentLocationPartner =
  buildCurrentLocationPropSelector('partner_key');

export const getCurrentLocationPayrollProvider =
  buildCurrentLocationPropSelector('payroll_provider');

export const getCurrentLocationFullStreetAddress =
  buildCurrentLocationPropSelector('full_street_address');

export const getCurrentLocationAddress1 =
  buildCurrentLocationPropSelector('address_1');

export const getCurrentLocationProximityEnforcement =
  buildCurrentLocationPropSelector('proximity_enforcement_enabled');

export const getCurrentLocationLaunchWeekSettings =
  buildCurrentLocationPropSelector('launch_week_settings');

export const getIsLaunchWeekWinner = state =>
  getCurrentLocationEntity(state).get('launch_week_settings')?.size > 0;

export const getCurrentLocationPartnerIsPayAnywhere = createSelector(
  getCurrentLocationPartner,
  partner => partner === 'pay_anywhere'
);

export const showAdpInterruptModal = buildCurrentLocationPropSelector(
  'showAdpInterruptModal'
);

export const getGoogleMapsApiLoaded = state =>
  state.getIn(['session', 'isGoogleMapsApiLoaded']);

export const getCurrentLocationSyncsRoles =
  buildCurrentLocationPropSelector('sync_roles');

export const getCurrentLocationCanHire =
  buildCurrentLocationSelector('can_hire');

export const getPredictedFundingFailureRequirement = state =>
  state.getIn([
    'session',
    'payroll',
    'company',
    'predictedFundingFailureRequirement',
  ]);

export const getPayrollRunIsPredictedFundingFailure = (state, runId) => {
  const requirementPayrollId = state.getIn([
    'session',
    'payroll',
    'company',
    'predictedFundingFailureRequirement',
    'payrollRunId',
  ]);
  return requirementPayrollId === runId;
};

export const getPredictedFailureWireIntention = state =>
  state.getIn([
    'session',
    'payroll',
    'company',
    'predictedFailureWireIntention',
  ]);

export const getPredictedFailurePaydayChanged = state =>
  state.getIn([
    'session',
    'payroll',
    'company',
    'predictedFailurePaydayChanged',
  ]);

export const getRolePermissions = state =>
  state.getIn(['session', 'permissions', 'role']);
export const getRolePermission = (state, { name }) =>
  getRolePermissions(state).get(name);
export const getPosPartners = state => state.getIn(['session', 'posPartners']);
export const getSelectableNonPosPartners = state =>
  state.getIn(['session', 'selectableNonPosPartners']);
export const getPosUrls = state => state.getIn(['session', 'posUrls']);
export const getPayrollUrls = state => state.getIn(['session', 'payrollUrls']);
export const getCurrentCompany = state =>
  state.getIn(['session', 'currentCompany']);
export const getCurrentCompanyName = state =>
  state.getIn(['session', 'currentCompany', 'name']);
export const getCurrentCompanyLocations = state =>
  state.getIn(['session', 'currentCompany', 'locations']);
export const getCurrentCompanyOwner = state =>
  state.getIn(['session', 'currentCompany', 'owner']);
export const getCurrentCompanyAdpReseller = state =>
  state.getIn(['session', 'currentCompany', 'adpReseller']);
export const getCurrentCompanyId = state =>
  state.getIn(['session', 'currentCompany', 'id']);
export const getPayrollCompany = state =>
  state.getIn(['session', 'payroll', 'company']);
export const getCompanyPayrollState = state =>
  state.getIn(['session', 'payroll', 'companyState']);
export const getAutopayrollSubmissionTimeSetting = state =>
  state.getIn(['session', 'payroll', 'autopayrollSubmissionTimeSetting']);
export const getCompanyEligibleForMultiLocationPayroll = state =>
  state.getIn(['session', 'payroll', 'companyEligibleForMultiLocationPayroll']);
export const getCheckCompanyId = state =>
  state.getIn(['session', 'payroll', 'company', 'checkCompanyId']);
export const getPayrollSetupInfoId = state =>
  state.getIn(['session', 'payroll', 'company', 'payrollSetupInfoId']);
export const getHasPayrollEnabled = state =>
  state.getIn(['session', 'payroll', 'hasPayrollEnabled']);
export const getCurrentCompanyManagers = state =>
  state.getIn(['session', 'companyManagers']);
export const getCurrentCompanyAge = state =>
  state.getIn(['session', 'currentCompany', 'age']);
export const getCurrentCompanyPayrollPaymentAttemptsInfo = state =>
  state.getIn(['session', 'payroll', 'paymentAttemptsInfo']);
export const getTimeOffCategories = state =>
  state.getIn(['session', 'timeOffCategories']);
export const getShowWebTimeclockTimesheetsMenu = state =>
  state.getIn(['session', 'web_timeclock_timesheets_menu']);
export const getAccountPermissions = state =>
  state.getIn(['session', 'accountPermissions']);
export const getTeamPermissions = state =>
  state.getIn(['session', 'teamPermissions']);
export const getRoundClockInsIncrements = state =>
  state.getIn(['session', 'roundClockInsIncrements']);
export const getOnboardingPartners = state =>
  state.getIn(['session', 'onboardingPartners']);
export const getGoogleMapsKey = state =>
  state.getIn(['session', 'googleMapKey']);
export const getPayrollPartners = state =>
  state.getIn(['session', 'payrollPartners']);

export const timesheetsRoutes = createSelector(
  getShowWebTimeclockTimesheetsMenu,
  showWebTimeclock => {
    const routes = TIMESHEETS_ROUTES;
    if (showWebTimeclock) {
      routes.push('/web_timeclock');
    }
    return routes;
  }
);

export const getPosPartnersForDashboardWidget = createSelector(
  getPosPartners,
  partners => {
    const dashboardPOSPartners = {
      ...DASHBOARD_WIDGET_POS_OPTIONS,
      ...CUSTOM_POS_NAMES,
    };

    partners
      .filter(partner => !dashboardPOSPartners[partner])
      .forEach(partner => (dashboardPOSPartners[partner] = titleize(partner)));

    delete dashboardPOSPartners.nab;
    delete dashboardPOSPartners.pay_anywhere;

    const selectOptions = Object.keys(dashboardPOSPartners).map(partner => ({
      label: dashboardPOSPartners[partner],
      value: partner,
    }));

    Object.freeze(
      selectOptions.sort((partnerA, partnerB) => {
        if (partnerA.label > partnerB.label) {
          return 1;
        }
        if (partnerA.label < partnerB.label) {
          return -1;
        }
        return 0;
      })
    );

    return selectOptions;
  }
);

// Rollouts
export const getRolloutEnabled = (state, key) =>
  state.getIn(['session', 'rollouts', key]);
export const getRollout = key => state => getRolloutEnabled(state, key);
export const getRollouts = state => state.getIn(['session', 'rollouts']);
export const getHiringApplicantJobAlertsOptInRollout = getRollout(
  'hiring_applicant_job_alerts_optin'
);

export const getLaunchWeekAward2024 = getRollout('launch_week_award_h1_2024');

export const getPayrollGrowthFunnelDesignbaseUpdatesRollout = getRollout(
  'payroll_growth_funnel_designbase_updates'
);

export const getPayrollCheckFeinValidationRollout = getRollout(
  'payroll_check_fein_validation'
);

export const getTaskManagerV1Rollout = getRollout('task_manager_v1');

export const getPayrollSssRemoveStartDateRollout = getRollout(
  'payroll_sss_remove_start_date'
);

export const getPayrollTeamMemberPaymentsWorkflowsRollout = getRollout(
  'payroll_team_member_payments_workflows'
);

export const getPayrollMultiLocationPhaseTwoRollout = getRollout(
  'payroll_multi_location_phase_2'
);

export const getPayrollAutoPayrollRolloutEnabled = getRollout('auto_payroll');

export const getRepackagingRollout = getRollout('repackaging');
export const getShowAnnouncementsV2 = getRollout('announcements_v2');
export const getTimesheetsCesEnabled = getRollout('timesheets_ces');

export const getScheduleCesEnabled = getRollout('schedule_ces');

export const getReactTimeOffModalEnabled = getRollout('react_time_off_modal');

export const getPayrollUpdateInformationRolloutEnabled = getRollout(
  'payroll_update_information'
);

export const getOBEDirectMailInactiveFreeTrialEnabled = getRollout(
  'obe_direct_mail_inactive_free_trial'
);

export const getOBEDirectMailReactivationFreeTrialEnabled = getRollout(
  'obe_direct_mail_reactivation_free_trial'
);

export const getShowEoyChecklistEnabled = getRollout('show_eoy_checklist');
export const getPayrollSsnValidationRolloutEnabled = getRollout(
  'payroll_ssn_validation'
);

export const getPayrollBlockBankingOpsWithoutMfaRolloutEnabled = getRollout(
  'payroll_block_banking_ops_without_mfa'
);

export const getRolloutObe16CloverWebOnboardingExperience = getRollout(
  'obe16_clover_web_onboarding_experience'
);

export const getRolloutObeEWOMQuestion = getRollout('obe_wom_question');

export const getDecoupledTierChangeWorkflowRolloutEnabled = getRollout(
  'decoupled_tier_change_workflow'
);

export const getGhostEmployeeCapV1RolloutEnabled = getRollout(
  'ghost_employee_cap_v1'
);

export const getMultiLocationEnforcementRolloutEnabled = getRollout(
  'multi_location_enforcement'
);

export const selectIsDowngradesWorkFlowRolloutEnabled = getRollout(
  'downgrades_workflow'
);

export const getEarlylifePayrollZeroStateOAMDemoRolloutEnabled = getRollout(
  'earlylife_payroll_zero_state_OAMdemo'
);
export const selectMonetizationTipManagerCheckoutRolloutEnabled = getRollout(
  'monetization_tip_manager_checkout'
);

export const getProductGrowthWelcomeCallPrimerRolloutEnabled = getRollout(
  'productgrowth_welcome_call_primer'
);

export const getProductGrowthSignUpPreopLauchdateRolloutEnabled = getRollout(
  'productgrowth_signup_preop_launch_date'
);

export const getPayrollGrowthManualYTDInfoEnabled = getRollout(
  'payroll_growth_manual_ytd_info_july24_pay_13130'
);

export const getProductGrowthCloverSplashScreenRolloutEnabled = getRollout(
  'productgrowth_clover_web_sign_up_splash_screen'
);

export const getRecommendationIdentifierRolloutEnabled = getRollout(
  'recommendation_identifier'
);

export const getBaseGuidedRetrialRolloutEnabled = getRollout(
  'base_guided_retrial_test'
);

export const getBaseMppRetrialRolloutEnabled = getRollout('base_mpp_retrial');

export const getPayrollAddressValidationRollout = getRollout(
  'payroll_address_validation'
);

export const getAutopayrollSubmissionTimeSettingRollout = getRollout(
  'autopayroll_submission_time_setting'
);

export const getProductGrowthReferralSignInRollout = getRollout(
  'productgrowth_referrals_sign_in'
);

// Experiments
export const getExperimentVersion = (state, experimentName) => {
  const experimentValue = state.getIn([
    'session',
    'experiments',
    experimentName,
  ]);

  return experimentValue ? parseFloat(experimentValue) : 0;
};

export const checkExperimentVersion = (
  state,
  experimentName,
  version,
  { withRollout }
) => {
  if (withRollout && !getRolloutEnabled(state, experimentName)) return false;

  const experimentVersion = getExperimentVersion(state, experimentName);

  if (Array.isArray(version)) {
    return version.map(parseFloat).includes(experimentVersion);
  }
  return parseFloat(version) === experimentVersion;
};

export const getExperimentEnabled = (
  state,
  experimentName,
  withRollout = true
) => checkExperimentVersion(state, experimentName, 1, { withRollout });

const buildGetExperimentEnabled =
  (experimentName, withRollout = true) =>
  state =>
    getExperimentEnabled(state, experimentName, withRollout);

export const getPreviousWeeksOnJumpstartDrawerEnabled = state =>
  checkExperimentVersion(state, 'previous_weeks_on_jumpstart_drawer', [1, 2], {
    withRollout: true,
  });

export const getHomebaseDashboardBannerVersion = state =>
  getExperimentVersion(state, 'promotion_homebase_dashboard_banner');

export const getPromoTimesheetsDownloadModalVersion = state =>
  getExperimentVersion(state, 'promotion_timesheets_download_modal');

export const selectEndOfTrialDisplayVersion = state =>
  getExperimentVersion(state, 'end_of_trial_display_version');

export const getEarlyLifeDiaryStudyExperienceVersion = state =>
  getExperimentVersion(state, 'earlylife_diary_study_experience');

export const selectIsManagePlansFocusViewEnabled = state =>
  getRolloutEnabled(state, 'manage_plans_focusview');

export const getHiringJobPostingFlowExperiment = buildGetExperimentEnabled(
  'hiring_job_posting_flow'
);

export const getShowAioTrialSelfEnrollment = buildGetExperimentEnabled(
  'aio_trial_self_enrollment'
);

export const getPayrollNavigationExperimentEnabled =
  buildGetExperimentEnabled('payroll_navigation');

export const getActivationPayrollEligibleExperimentEnabled =
  buildGetExperimentEnabled('activation_payroll_eligible');

export const getEmployeeProfileRosterExperimentEnabled =
  buildGetExperimentEnabled('employee_profile_roster');

export const getGhostPackagingManagePlanPageExperimentEnabled =
  buildGetExperimentEnabled('ghost_packaging_manage_plan_page');

export const getCpAddTeamMemberFlowEnabled = buildGetExperimentEnabled(
  'cp_add_team_member_flow'
);

export const getLimitRoleDeptPageToOwnersEnabled = buildGetExperimentEnabled(
  'limit_role_dept_page_to_owners'
);

export const getMobileTimeClockEnforcementEnabled = buildGetExperimentEnabled(
  'mobile_time_clock_enforcement'
);

export const getRe8PayrollEnabled = buildGetExperimentEnabled('re8_payroll');

export const getHelpCenterAIChatEnabled = buildGetExperimentEnabled(
  'help_center_ai_chat'
);

export const getStarterPackageEnabled = buildGetExperimentEnabled(
  'earlylife_starter_package_manage_plan'
);

export const getDiaryStudyExperience = buildGetExperimentEnabled(
  'earlylife_diary_study_experience'
);

export const getProductGrowthSignupOnboardingStyleRolloutEnabled = getRollout(
  'productgrowth_signup_onboarding_style'
);

export const getProductGrowthSignupOnboardingStyle = buildGetExperimentEnabled(
  'productgrowth_signup_onboarding_style'
);

export const getPayrollTeamReadinessV2Enabled = buildGetExperimentEnabled(
  'payroll_growth_team_module_update_mar24_pay_12679'
);

export const getDay60CbFirstNudgeExperimentEnabled = buildGetExperimentEnabled(
  'day60_cb_first_nudge'
);

export const getSbCopyByDepartmentExperimentEnabled = buildGetExperimentEnabled(
  'sb_copy_by_department'
);

export const getSbReactForNewCompanyEnabled = state =>
  checkExperimentVersion(state, 'react_sb_new_company', 1, {
    withRollout: true,
  });

export const getSbReactForcedEnabled = state =>
  checkExperimentVersion(state, 'demo_react_schedule_builder', 2, {
    withRollout: true,
  });

export const getPayScheduleStepExperimentEnabled = state =>
  getExperimentEnabled(
    state,
    'payroll_growth_pay_schedule_update_switcher_may24_pay_13177'
  ) ||
  getExperimentEnabled(
    state,
    'payroll_growth_pay_schedule_update_inception_may24_pay_13177'
  );

export const getAdminAccessStepExperimentEnabled = state =>
  getExperimentEnabled(
    state,
    'payroll_growth_admin_access_step_june24_pay_13411'
  );

export const getReactSbWeekViewEnabled = state =>
  getSbReactForNewCompanyEnabled(state) ||
  checkExperimentVersion(state, 'demo_react_schedule_builder', [1, 2], {
    withRollout: true,
  });

export const getCommonShiftsEnabled = state =>
  buildGetExperimentEnabled('sb_common_shifts')(state);

export const getReferralRedesignEnabled = getRollout(
  're3_web_referral_redesign'
);

export const getReferralIncentiveVersion = state =>
  getRolloutEnabled(state, 're5_web_referral_incentive') &&
  state.getIn([
    'session',
    'currentUser',
    'userReferrals',
    're5_web_referral_incentive',
  ]);

// Feature Flags
const buildGetShow = key => state =>
  state.getIn(['session', 'featureFlags', key]);

export const getCompanyTimesheetsEnabled = buildGetShow(
  'companyTimesheetsEnabled'
);

export const getCompanyTimesheetsAccessTier = buildGetShow(
  'companyTimesheetsAccessTier'
);

export const getIsPayrollEnrolled = buildGetShow('isPayrollEnrolled');

export const getHealthQuestionsEnabled = buildGetShow(
  'isHealthQuestionsEnabled'
);

export const getCompanyReactivationEnabled = buildGetShow(
  'companyReactivationEnabled'
);

export const getRepackagingVersion = buildGetShow('repackagingVersion');
export const getRepackagingEnabled = buildGetShow('repackagingEnabled');
export const plaidDirectDepositEnabled = buildGetShow(
  'plaidDirectDepositEnabled'
);
export const getNewYorkBreakRuleEnabled = buildGetShow(
  'newYorkBreakRuleEnabled'
);

export const getMultiLocationTrialEligible = buildGetShow(
  'multiLocationTrialEligible'
);

export const getShowSeventhDayOvertimeSettings = buildGetShow(
  'showSeventhDayOvertimeSettings'
);

export const getShowSplitShift = buildGetShow('splitShiftEnabled');
export const getShowPhoneInputMask = buildGetShow('phoneInputMask');
export const getShowSpreadOfHours = buildGetShow('spreadOfHours');
export const getShowBlueLawsHours = buildGetShow('blueLawsHours');
export const getBlueLawsHoursRate = buildGetShow('blueLawsHoursRate');
export const getShowRhodeIslandOvertime = buildGetShow('rhodeIslandOvertime');
export const getShowSelfLocationCreate = buildGetShow('selfLocationCreate');
export const getIsDemoSession = buildGetShow('demoSession');

export const getShowGeofenceClockinLocationTooltip = buildGetShow(
  'showGeofenceClockinLocationTooltip'
);

export const getShowAddShiftNotesReminder = buildGetShow(
  'showAddShiftNotesReminder'
);

export const getShowHiringWidgetEmpty = buildGetShow('hiringWidgetEmpty');
export const getShowHiringWidgetJobPosts = buildGetShow('hiringWidgetJobPosts');

export const getShowHiringWidgetThreeTabs = buildGetShow(
  'hiringWidgetThreeTabs'
);

export const getCompanyOneTimeEvents = state => {
  const events = state.getIn([
    'session',
    'featureFlags',
    'companyOneTimeEvents',
  ]);
  return events ? events.toSet() : Set();
};

export const getUserOneTimeEvents = state => {
  const events = state.getIn(['session', 'featureFlags', 'userOneTimeEvents']);
  return events ? events.toSet() : Set();
};

export const getCompanyOneTimeEventDone = (state, eventName) =>
  getCompanyOneTimeEvents(state).has(eventName);

export const getUserOneTimeEventDone = (state, eventName) =>
  getUserOneTimeEvents(state).has(eventName);

export const getAccountOneTimeEventDone = (state, eventName) =>
  getUserOneTimeEvents(state).has(eventName);

export const getShowTeamPayBaseModal = state =>
  !getAccountIsHub(state) &&
  !getUserOneTimeEventDone(state, 'team_pay_base_modal') &&
  checkExperimentVersion(state, 'pay_base', 2, { withRollout: true });

export const getShowDashboardPayBaseModal = state =>
  !getUserOneTimeEventDone(state, 'dashboard_pay_base_modal') &&
  checkExperimentVersion(state, 'pay_base', 1, { withRollout: true }) &&
  getCurrentCompanyLocations(state).size === 1;

export const getOldTimesheetsGuidedSetupComplete = state =>
  getUserOneTimeEventDone(state, 'timesheets_guided_setup_completed');

export const getNewTimesheetsGuidedSetupComplete = state =>
  getUserOneTimeEventDone(state, 'module_onboarding_qsg_clock_in_task');

export const getDailyReviewGuidedTimesheetsTooltipCompleted = state =>
  getUserOneTimeEventDone(
    state,
    'daily_review_guided_timesheets_tooltip_completed'
  );

export const getTimesheetsGuidedSetupComplete = createSelector(
  getOldTimesheetsGuidedSetupComplete,
  getNewTimesheetsGuidedSetupComplete,
  (oldComplete, newComplete) => oldComplete || newComplete
);

export const getIsMultiLocation = state =>
  getCurrentCompanyLocations(state).size > 1;

export const getPreventMultipleLocationsDowngrade = createSelector(
  getShowSelfLocationCreate,
  getCurrentCompanyHasMultipleLocations,
  (locationCreateEnabled, multipleLocations) =>
    locationCreateEnabled && multipleLocations
);

export const getShowUpsellAndMetering = buildGetShow('showUpsellAndMetering');
export const getShowShiftTradeUpsellInterrupt = buildGetShow(
  'showShiftTradeUpsellInterrupt'
);
export const getShowTimesheetExportUpsellInterrupt = buildGetShow(
  'showTimesheetExportUpsellInterrupt'
);

export const getDashboardProductUsageFeatures = buildGetShow(
  'dashboardProductUsageFeatures'
);

// TODO: Remove in GROWTH-146
export const getDashboardProductUsageFeaturesCopy = buildGetShow(
  'dashboardProductUsageFeaturesCopy'
);

export const getShowIsHiringBoostPopupEnabled = buildGetShow(
  'isHiringBoostPopupEnabled'
);

export const getShowIsHiringTerminationLandJobPostEnabled = buildGetShow(
  'isHiringTerminationLandJobPostEnabled'
);

export const getShowScheduleSmsDiscoverability = buildGetShow(
  'showScheduleSmsDiscoverability'
);

export const getShowPayrollPartners = buildGetShow('showPayrollPartners');
export const getShowGeofenceOverlay = buildGetShow('showGeofenceOverlay');
export const getIsAutoPayrollFeatureEnabled = buildGetShow(
  'isAutoPayrollFeatureEnabled'
);

export const getShowMobileInterstitial = state =>
  state.getIn(['session', 'currentUser', 'showMobileInterstitial']);

export const getShowOnboardingMobileInterstitial = state =>
  state.getIn(['session', 'currentUser', 'showOnboardingMobileInterstitial']);

export const getHasVisitedHiringBefore = state =>
  state.getIn(['session', 'currentUser', 'hasVisitedHiringBefore']);

export const getCurrentUser = state => state.getIn(['session', 'currentUser']);
export const getUserEmployedByEmbeddedCloverLocation = state =>
  state.getIn([
    'session',
    'currentUser',
    'user_employed_by_embedded_clover_location',
  ]);

export const getCurrentUserHasPassword = state =>
  state.getIn(['session', 'currentUser', 'has_password']);

export const getHasW2Jobs = state =>
  state.getIn(['session', 'currentUser', 'hasW2Jobs']);

export const getPayrollBankAccount = state =>
  state.getIn(['session', 'currentUser', 'payroll_bank_account']);

export const getCurrentUserSignInCount = state =>
  state.getIn(['session', 'currentUser', 'sign_in_count']);
export const getCurrentUserHasUsedMobile = state =>
  state.getIn(['session', 'currentUser', 'has_used_mobile']);
export const getCurrentUserIsOwner = state =>
  state.getIn(['session', 'currentUser', 'is_owner']);
export const getCurrentUserSignature = state =>
  state.getIn(['session', 'currentUser', 'signature']);
export const getCurrentUserIsGM = state =>
  state.getIn(['session', 'currentUser', 'is_gm']);
export const getCurrentUserIsManagerial = state =>
  state.getIn(['session', 'currentUser', 'is_managerial']);
export const getCurrentUserIsArchived = state =>
  state.getIn(['session', 'currentUser', 'is_archived']);
export const getCurrentUserIsPayrollAdmin = state =>
  state.getIn(['session', 'currentUser', 'is_payroll_admin']);
export const getFirstTimePopupShown = (state, { popupKey }) =>
  state.getIn(['session', 'currentUser', 'first_time_popups', popupKey]) ===
  'true';
export const getCurrentUserId = state =>
  state.getIn(['session', 'currentUser', 'id']);
export const getCurrentUserFirstName = state =>
  state.getIn(['session', 'currentUser', 'first_name']);
export const getCurrentUserLastName = state =>
  state.getIn(['session', 'currentUser', 'last_name']);
export const getCurrentUserInitials = state =>
  `${state.getIn(['session', 'currentUser', 'first_name']).charAt(0)}${state
    .getIn(['session', 'currentUser', 'last_name'])
    .charAt(0)}`;

export const getCurrentUserEmail = state =>
  state.getIn(['session', 'currentUser', 'email']);
export const getCurrentUserPhone = state =>
  state.getIn(['session', 'currentUser', 'phone']);
export const getOnboardingTrialDaysLeft = state =>
  state.getIn(['session', 'onboardingTrialDaysLeft']);
export const getOnboardingTrialSource = state =>
  state.getIn(['session', 'onboardingTrialSource']);
export const getCurrentUserJobLocationIds = state =>
  state.getIn(['session', 'currentUser', 'job_location_ids']);
export const getCurrentUserJobAccessLevels = state =>
  state.getIn(['session', 'currentUser', 'job_access_levels']);
export const getCurrentCompanySegmentedOnboardingName = state =>
  state.getIn(['session', 'currentCompany', 'segmentedOnboardingName']);
export const getCurrentCompanyonboardingRedirectPath = state =>
  state.getIn(['session', 'currentCompany', 'onboardingRedirectPath']);
export const getPayrollHowYouPayToday = state =>
  state.getIn(['session', 'currentCompany', 'payrollHowYouPayToday']);
export const getWssConfig = state =>
  state.getIn(['session', 'currentUser', 'wssConfig']);
export const getLocationStartOfWeek = state =>
  state.getIn(['session', 'currentLocation', 'start_of_week']);

export const getTaxSetupComponent = state =>
  state.getIn(['session', 'payroll', 'settings', 'tax_setup_component']);

export const getStateFilingAuthorizationComponent = state =>
  state.getIn([
    'session',
    'payroll',
    'settings',
    'state_filing_authorization_component',
  ]);

export const getShowPayrollQuizBanner = state =>
  state.getIn(['session', 'payroll', 'showPayrollQuizBanner']);

export const getPayrollDashboardBannerDate = state =>
  state.getIn(['session', 'payroll', 'payrollDashboardBannerDate']);

export const getCurrentUserTwoFaRequirementMet = state =>
  state.getIn(['session', 'payroll', 'twoFaRequirementMet']);

// User Referrals
const buildCurrentUserReferralsSelector = key => state =>
  state.getIn(['session', 'currentUser', 'userReferrals', key]);
export const getCurrentUserSocialShareLinks =
  buildCurrentUserReferralsSelector('socialShareLinks');
export const getCurrentUserUserReferralLink =
  buildCurrentUserReferralsSelector('userReferralLink');
export const getUserReferralsDollarAmount = buildCurrentUserReferralsSelector(
  'userReferralsDollarAmount'
);
export const getUserReferralsDynamicDollarAmount =
  buildCurrentUserReferralsSelector('userReferralsDynamicDollarAmount');

export const getHasActiveTrialPeriod = state =>
  state.getIn(['session', 'hasActiveTrialPeriod']);

export const getCurrentUserAndLocationExists = createSelector(
  getCurrentLocation,
  getCurrentUser,
  (currentLocation, currentUser) => !!currentLocation && !!currentUser
);

export const getMessagingEnabled =
  buildCurrentLocationSelector('messaging_enabled');
export const getWebTimeClockEmployeeAccess = buildCurrentLocationSelector(
  'web_timeclock_employee_access'
);
export const getTimesheetApprovalEnabled = buildCurrentLocationSelector(
  'timesheet_approval_enabled'
);
export const getMobileTimeClockEnabled = buildCurrentLocationSelector(
  'mobile_timeclock_enabled'
);

export const isOnboardPlusTrial = state =>
  state.getIn(['session', 'isOnboardPlusTrial']);

// Permissions
export const getCanManageGMRole = createSelector(
  getCurrentUserIsGM,
  getCurrentUserIsOwner,
  (isGM, isOwner) => isGM || isOwner
);

export const getCanViewWages = state =>
  getRolePermission(state, { name: 'view_wages' });
export const getCanViewLabor = state =>
  getRolePermission(state, { name: 'view_labor' });
export const getCanViewReports = state =>
  getRolePermission(state, { name: 'view_reports' });
export const getCanManageRoles = state =>
  getRolePermission(state, { name: 'manage_roles' });
export const getCanManageJobs = state =>
  getRolePermission(state, { name: 'manage_jobs' });
export const getCanManageLocation = state =>
  getRolePermission(state, { name: 'manage_location' });
export const getCanManageTimecards = state =>
  getRolePermission(state, { name: 'manage_timecards' });
export const getCanManagePermissions = state =>
  getRolePermission(state, { name: 'manage_permissions' });
export const getCanManageHealthQuestions = state =>
  getRolePermission(state, { name: 'manage_safety_questions' });
export const getCanManageCompletedDocuments = state =>
  getRolePermission(state, { name: 'view_completed_documents' });
export const getCanManageShiftNotes = state =>
  getRolePermission(state, { name: 'shift_notes' });
export const getCanManageTier = state =>
  getRolePermission(state, { name: 'manage_tier' });
export const getCanManageSchedule = state =>
  getRolePermission(state, { name: 'manage_schedule' });

// Feature Permissions
export const getFeaturePermissions = state =>
  state.getIn(['session', 'permissions', 'feature']);
export const getFeaturePermission = (state, { name }) =>
  getFeaturePermissions(state)?.get(name);
export const getHasFeature = (state, { feature }) =>
  state.getIn(['session', 'permissions', 'feature', feature]);
export const getHasReport = (state, { report }) =>
  state.getIn(['session', 'permissions', 'reports', report]);

const buildGetHasFeature = feature => state =>
  getHasFeature(state, { feature });
export const getHasPTO = buildGetHasFeature('pto');
export const getHasOnboardingDocs = buildGetHasFeature('new_hire_onboarding');
export const getHasRoleWages = buildGetHasFeature('role_wages');
export const getMessagingReadReceipts = buildGetHasFeature(
  'messaging_read_receipts'
);

export const getFeatureMinTiers = state =>
  state.getIn(['session', 'permissions', 'featureMinTiers']);

export const getReportMinTiers = state =>
  state.getIn(['session', 'permissions', 'reportMinTiers']);

export const getLockTimesheetRolePermission = state =>
  getRolePermission(state, { name: 'lock_timesheet' });

export const getLockTimesheetPermission = createSelector(
  getTimesheetApprovalEnabled,
  getLockTimesheetRolePermission,
  (feature_access, role_access) => feature_access && role_access
);

const buildGetFeatureMinTier = feature => state =>
  getFeatureMinTiers(state).get(feature);
export const getRoleWagesMinTier = buildGetFeatureMinTier('role_wages');
export const getMessagingReadReceiptsMinTier = buildGetFeatureMinTier(
  'messaging_read_receipts'
);
export const getHrProMinTier = buildGetFeatureMinTier('hr_pro');

export const getFeatureMinTier = (state, { feature }) =>
  state.getIn(['session', 'permissions', 'featureMinTiers', feature]);

export const getFeatureMinTierData = createSelector(
  getTiers,
  getFeatureMinTier,
  (tiers, featureMinTier) =>
    tiers?.find(tier => tier.get('name') === featureMinTier)
);

export const getIsPaidFeature = (state, { feature }) => {
  const minTier = getFeatureMinTier(state, { feature });

  return feature && minTier && minTier !== 'basic';
};

export const getCanEditLocation = createSelector(
  getCanManageLocation,
  getCurrentUserIsOwner,
  (canManageLocation, isOwner) => canManageLocation || isOwner
);

export const getTimecardPhotosEnabled = createSelector(
  state => getHasFeature(state, { feature: 'timecard_photos' }),
  getRepackagingEnabled,
  (hasFeature, repackagingEnabled) => (repackagingEnabled ? hasFeature : true)
);

export const getMinTierNameForMobileGpsPhotosFeatures = createSelector(
  state => getFeatureMinTiers(state).get('mobile_gps_photos'),
  tierName => capitalizeFirstLetter(tierName)
);

export const getTimeOffCategoryOptions = createSelector(
  getTimeOffCategories,
  categories =>
    categories.map(category => ({
      value: category.get('key'),
      label: category.get('name'),
    }))
);

// Nav
export const getNavType = state => state.getIn(['session', 'nav', 'type']);
export const getIsOnboarding = state =>
  state.getIn(['session', 'nav', 'isOnboarding']);
export const getSignInUrl = state =>
  state.getIn(['session', 'nav', 'signInUrl']);

export const getShowPayrollCountdownBanner = state =>
  state.getIn(['session', 'payroll', 'showPayrollCountdownBanner']);

// Hiring
export const getCurrentApplicantId = state =>
  state.getIn(['session', 'currentApplicant', 'id']);

export const getApplicantById = createSelector(
  getApplicants,
  getCurrentApplicantId,
  (applicants, id) => applicants.get(id.toString())
);

export const getCurrentApplicantFirstTimePopupShown = (state, { popupKey }) =>
  state.getIn([
    'session',
    'currentApplicant',
    'first_time_popups',
    popupKey,
  ]) === 'true';

export const getShowOnboardingQuestionsModal = state =>
  state.getIn(['session', 'currentCompany', 'showOnboardingQuestionsModal']);

export const getCurrentCompanyCanAddSecondLocationOnTrial = state =>
  state.getIn(['session', 'currentCompany', 'canAddSecondLocationOnTrial']);

export const getCurrentCompanyHasNoJobRequests = state =>
  state.getIn(['session', 'currentCompany', 'hasNoJobRequests']);

export const getCurrentCompanyIsEligibleForDsu = state =>
  state.getIn(['session', 'currentCompany', 'isEligibleForDsu']);

export const getCurrentCompanyIsEligibleForDiaryStudyInitialScreens = state =>
  state.getIn([
    'session',
    'currentCompany',
    'isEligibleForDiaryStudyInitialScreens',
  ]);

export const getLeftHiringToNonHiringRails = state =>
  state.getIn(['session', 'currentUser', 'leftHiringToNonHiringRails']);

export const getMobileAppDownloadBannerData = state =>
  state.getIn(['session', 'currentUser', 'mobileAppDownloadBannerLinks']);

export const getAvailablePartners = state =>
  state.getIn(['session', 'availablePartners']);

export const selectActiveUsersCount = state =>
  state.getIn(['session', 'activeUsersCount']);

const buildCurrentCompanySelector = key => state =>
  state.getIn(['session', 'currentCompany', key]);

export const getCompanyOnboardingSegment =
  buildCurrentCompanySelector('onboarding_segment');

export const getCompanyIsDemo = buildCurrentCompanySelector('demo');
export const getCompanyIsFake = buildCurrentCompanySelector('fake');
export const getCompanyIsFakeOrDemo = createSelector(
  getCompanyIsDemo,
  getCompanyIsFake,
  (demo, fake) => demo || fake
);
export const getCurrentCompanyOnboardSource =
  buildCurrentCompanySelector('onboard_source');

export const getCompanyHasJobRequests = buildCurrentCompanySelector(
  'companyHasJobRequests'
);

export const getCompanyHasJobApplications = buildCurrentCompanySelector(
  'companyHasJobApplications'
);

export const showHiringApplicantZeroState = createSelector(
  getCompanyHasJobRequests,
  getCompanyHasJobApplications,
  (hasJobRequests, hasApplications) => !hasJobRequests && !hasApplications
);

export const isPayAnywhere = createSelector(
  getCurrentCompanyOnboardSource,
  onboardSource => onboardSource === 'PayAnywhere'
);

export const getPayrollPartnersForSettings = createSelector(
  getPayrollPartners,
  getCurrentCompanyAdpReseller,
  (_, props) => props.location,
  (payrollPartners, adpReseller, location) => {
    if (location.get('payroll_partner_name') === 'ADP Run' && adpReseller) {
      return payrollPartners.filter(p =>
        ['ADP Run', 'Excel / CSV'].includes(p.get('name'))
      );
    }

    return payrollPartners;
  }
);

export const getCurrentLocationManualSalesEnabled = createSelector(
  getSelectableNonPosPartners,
  getCurrentLocationPartner,
  (partners, partner) => partners.includes(partner)
);

const getAnnouncements = state =>
  state.getIn(['session', 'currentUser', 'announcements']) || Map();

export const getAnnouncementsForCurrentPath = createSelector(
  (_state, { pathname }) => pathname,
  getAnnouncements,
  (pathname, announcements) =>
    announcements.filter(announcement =>
      pathname.includes(announcement.get('target_path'))
    )
);

const buildAnnouncementPlacementSelector = placement =>
  createSelector(
    getAnnouncements,
    announcements =>
      announcements.find(
        announcement =>
          announcement.get('can_show') === true &&
          announcement.get('target_path_match') === true &&
          announcement.get('placement') === placement
      ) || Map()
  );

export const getModalAnnouncementForCurrentPath =
  buildAnnouncementPlacementSelector(MODAL);
export const getBannerAnnouncementForCurrentPath =
  buildAnnouncementPlacementSelector(BANNER);

export const getShowBillingModalPhoneCopy = buildGetShow(
  'showBillingModalPhoneCopy'
);

const getShowScrimEnabled = state => {
  if (state.getIn(['session', 'showScrim']) === undefined) return true;
  return state.getIn(['session', 'showScrim']);
};

export const getShowCovidReactivationScrim = (state, scrimEvent) =>
  !getAccountIsHub(state) &&
  getShowScrimEnabled(state) &&
  getCompanyReactivationEnabled(state) &&
  getCurrentUserIsManagerial(state) &&
  !getModalType(state) &&
  !getCompanyOneTimeEventDone(state, scrimEvent);

export const getShowCovidReactivationMessengerScrim = state => {
  const currentRoute = browserHistory.location.pathname;
  const otherPagesMap = Map({
    covid_scrim_team_actions: '/team',
    covid_scrim_dashboard_checklist: '/dashboard/location',
  });
  return (
    getShowCovidReactivationScrim(state, 'covid_scrim_messenger_view') &&
    (!otherPagesMap.some(route => currentRoute.includes(route)) ||
      otherPagesMap.some(
        (route, scrimEvent) =>
          currentRoute.includes(route) &&
          getCompanyOneTimeEventDone(state, scrimEvent)
      ))
  );
};

export const showTipPooling = state => getTipPoolingRolloutEnabled(state);

export const getPopularRolesForCurrentLocation = createSelector(
  getCurrentLocationEntity,
  location => location.get('popular_roles')
);

export const getShowTipCreditsSettings = state =>
  state.getIn(['session', 'payroll', 'showTipCreditsSettings']);

export const getShowTipShortageEnabled = createSelector(
  getCurrentLocationEntity,
  location => location.get('tip_shortage_enabled')
);

export const getHomebasePayrollVisibilityEligible = createSelector(
  getPayrollNavigationExperimentEnabled,
  getIsPayrollEnrolled,
  getCurrentUserIsPayrollAdmin,
  (payrollNavigationEnabled, isPayrollEnrolled, currentUserIsPayrollAdmin) =>
    payrollNavigationEnabled && !isPayrollEnrolled && currentUserIsPayrollAdmin
);

export const selectShouldShowApprovalsPaywall = createSelector(
  getCurrentLocationTierName,
  currentLocationTierName =>
    currentLocationTierName === tierConstants.TIER_NAMES.BASIC
);

export const getEditHistoryEnabled = state =>
  getHasFeature(state, { feature: 'timecard_history' });

export const getMinTierNameForEditHistory = createSelector(
  state => getFeatureMinTiers(state).get('timecard_history'),
  tierName => capitalizeFirstLetter(tierName)
);

export const getStreamlineTimecardReviewExperimentVersion = state =>
  getExperimentVersion(state, 'streamline_timecard_review');

export const getStreamlineTimecardReviewRolloutEnabled = getRollout(
  'streamline_timecard_review'
);

export const getTipPoolingRolloutEnabled =
  getRollout('tip_pooling') || getRollout('tip_pooling_ga');

export const getTipPoolingGARolloutEnabled = getRollout('tip_pooling_ga');

export const getShowTipPoolingChangeWarning = state =>
  state.getIn(['session', 'payroll', 'showTipPoolingChangeWarning']);

export const getShowStrideHealthBanner = state =>
  state.getIn(['session', 'growth', 'showStrideHealthBanner']);

export const getShowStrideHealthGreenAlert = state =>
  state.getIn(['session', 'growth', 'showStrideHealthGreenAlert']);

export const getIsTipPoolingExpV2Enabled = state =>
  getTipPoolingGARolloutEnabled(state) ||
  (getTipPoolingRolloutEnabled(state) &&
    getExperimentVersion(state, 'tip_pooling') >= 2);

export const getIsTipPoolingExpV4Enabled = state =>
  getTipPoolingGARolloutEnabled(state) ||
  (getTipPoolingRolloutEnabled(state) &&
    getExperimentVersion(state, 'tip_pooling') >= 4);

export const getActiveTipPolicyId = state =>
  state.getIn(['session', 'currentLocation', 'active_tip_policy_id']);

export const getCurrentLocationPartnerIsCashRegister = createSelector(
  getCurrentLocationPartner,
  partnerKey => partnerKey === 'cash_register'
);
