import React from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';

import PurchaseMethodModule from 'features/biller/implementations/stripe/PurchaseMethodModule';
import PurchaseMethodButton from 'features/biller/implementations/stripe/PurchaseMethodModule/PurchaseMethodButton';
import { Price } from 'features/biller/implementations/stripe/PurchaseMethodModule/PurchaseMethodModuleView';

import { toI18n } from 'util/i18n';

interface CheckoutProps {
  onSuccess: () => void;
  onLoading: (isLoading: boolean) => void;
  isLoading: boolean;
  locationName: string;
  price: Price;
}
const Checkout: React.FC<CheckoutProps> = ({
  locationName,
  onSuccess,
  onLoading,
  isLoading,
  price,
}) => (
  <>
    <Box ph={32}>
      <Box gap={12} column>
        <Text variant="heading2" i18n="checkout_drawer.tip_manager.title" />
        <Text
          variant="body"
          color="mono900"
          i18n="checkout_drawer.tip_manager.subtitle"
          i18nProps={{ locationName }}
          asHTML
        />
      </Box>
      <PurchaseMethodModule
        onSuccess={onSuccess}
        onLoading={onLoading}
        price={price}
      />
      <Box mt={24}>
        <Text
          variant="bodySm"
          color="mono900"
          i18n="checkout_drawer.tip_manager.footer"
          asHTML
        />
      </Box>
    </Box>
    <Box sticky hcenter mt={32} p={32} bottom={0} bgcolor="mono0">
      <PurchaseMethodButton
        fullWidth
        variant="primary"
        isLoading={isLoading}
        uxElement="confirm_and_pay_btn"
      >
        {toI18n('checkout_drawer.tip_manager.cta')}
      </PurchaseMethodButton>
    </Box>
  </>
);

export default Checkout;
